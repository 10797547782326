import * as React from 'react';
import {displayDictionary} from '../utils/displayDictionary';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Theme} from '@mui/material';
// { field: 'id', headerName: 'ID', width: 70 },

function getColumns(data: any): GridColDef[] {
    if (!data) return [];
    return Object.keys(data)
        .filter(k => k !== 'id')
        .map(k => {
            const display = displayDictionary[k];
            const width = display.length * 12;
            return {
                field: k,
                headerName: display,
                width
            };
        });
}

export default function DataTable({selections, theme}: {selections: any; theme: Theme}) {
    return (
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: theme.schemes.light.surface
                    }
                }}
                rows={selections}
                columns={getColumns(selections[0])}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 5}
                    }
                }}
                pageSizeOptions={[5, 50]}
                checkboxSelection
            />
        </div>
    );
}
