import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WidgetBox from '../../components/WidgetBox';
import {getDate} from '../../utils/utils';

interface SideWidgetProps {
    subscription: any;
    paymentSource: any;
    plan: any;
}

const SideWidget: React.FC<SideWidgetProps> = ({subscription, paymentSource, plan}) => {
    const {year, month, day} = getDate(subscription.next_billing_at);
    if (paymentSource.length === 0) return null;
    return (
        <WidgetBox title="Your upcoming bill">
            <Box display="flex" flexDirection="column" gap="15px" marginTop="24px">
                <Typography variant="title" size="large" color="on-surface-variant">
                    {`$${
                        subscription?.total_dues ? (subscription?.total_dues / 100).toFixed(2) : (plan.price / 100).toFixed(2)
                    } will be charged to your credit card on file on ${month} ${day}, ${year}.`}
                </Typography>
                <Typography variant="body" size="large" color="on-surface-variant">
                    Card ending in {paymentSource[0]?.card?.last4}
                </Typography>
            </Box>
        </WidgetBox>
    );
};

export default SideWidget;
