import React from 'react';

import {ChipSelect} from '../../inputs/ChipSelect';
import {updateFunc} from '../../../types';
import {PCAProps} from './index';

type PCAControllerProps = PCAProps & {
    update: updateFunc;
};
export function PCAController({geneList = [], geneLists = [], update}: PCAControllerProps) {
    return (
        <div>
            <ChipSelect
                title={'Select a Genelist'}
                condition={geneList.length < 2}
                tip={'Choose a gene list to plot'}
                name={'geneList'}
                currentSelections={geneList}
                selectionList={geneLists}
                update={update}
            />
        </div>
    );
}
