import React, {useEffect, useState, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Modal, Popper, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ArrowBack} from '@mui/icons-material';
import {getAppConfig, useBff, useTrovoConfig} from '../../utils/config';
import {ButtonRow} from '../../components/ButtonRow';
import ManageSamplesTable from './ManageSamplesTable';
import BannerMessage from '../AccountDetails/BannerMessage';
import {useAuth0} from '@auth0/auth0-react';
import {demoSamples, demoExperiments} from '../../utils/mock-data';
import FilterList from '../../components/FilterList';

const PageContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1.25% 8% '
});

interface Sample {
    id: string;
    name: string;
    status?: string;
    organism: string;
    other_categories?: Record<string, any>;
    [key: string]: any;
}

function ManageSamples() {
    let {id} = useParams();
    const navigate = useNavigate();
    const {user} = useTrovoConfig();
    const {apiHost} = getAppConfig();
    const {getAccessTokenSilently} = useAuth0();
    const [samples, setSamples] = useState<Sample[]>([]);
    const [experiment] = useBff('GET', `experiment/${id}`);
    const [addingSample, setAddingSample] = useState(false);
    const [showFilters, setShowFilters] = useState(null);
    const [filteredList, setFilteredList] = useState(null);
    const [allSamples] = useBff('GET', `experiment/${id}/sample`);
    const [deleteCallback, setDeleteCallback] = useState<any>(null);
    const [selectedSamples, setSelectedSamples] = useState(new Set());
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [isDuplicationStarted, setDuplicationStarted] = useState<any>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const selectedSamplesArray = Array.from(selectedSamples).map(value => ({value: value as string}));

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredSamples = useMemo(() => {
        if (!searchTerm) return samples;
        return samples.filter(sample => {
            const directSearch = ['name', 'organism', 'status'].some(key => {
                return sample[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase());
            });
            if (directSearch) return true;
            if (sample.other_categories) {
                return Object.entries(sample.other_categories).some(([key, value]) => {
                    if (typeof value === 'string') {
                        return value.toLowerCase().includes(searchTerm.toLowerCase());
                    } else if (Array.isArray(value)) {
                        return value.some(element => element.toString().toLowerCase().includes(searchTerm.toLowerCase()));
                    }
                    return false;
                });
            }
            return false;
        });
    }, [samples, searchTerm]);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedSamples = new Set(filteredSamples.map((d: any) => d.id));
            setSelectedSamples(newSelectedSamples);
            setSelectAllChecked(true);
        } else {
            setSelectedSamples(new Set());
            setSelectAllChecked(false);
        }
    };

    const handleSelectRow = (id: string) => {
        const newSelectedSamples = new Set(selectedSamples);
        if (selectedSamples.has(id)) {
            newSelectedSamples.delete(id);
        } else {
            newSelectedSamples.add(id);
        }
        setSelectedSamples(newSelectedSamples);
        setSelectAllChecked(newSelectedSamples.size === filteredSamples?.length);
    };

    const handleRemoveSample = (sampleId: string | undefined) => {
        if (typeof sampleId === 'string') {
            setSelectedSamples(prevSelectedSamples => {
                const updatedSelectedSamples = new Set(Array.from(prevSelectedSamples));
                updatedSelectedSamples.delete(sampleId);

                return updatedSelectedSamples;
            });
        }
    };

    const reFetchSamples = async () => {
        const response = await fetch(`${apiHost}/experiment/${id}/sample`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`
            }
        });
        const result = await response.json();
        setSamples(result);
    };

    useEffect(() => {
        setSamples(id === '4015c7cc-1982-4bd4-ae1c-140a7aa6fba9' ? demoSamples : allSamples);
    }, [allSamples?.length, addingSample]);

    return (
        <PageContainer>
            <Button
                startIcon={<ArrowBack />}
                variant="outlined"
                sx={{my: 2, borderRadius: '50px', width: '215px'}}
                onClick={() => navigate(`/experiment/${id}`)}>
                Back to this experiment
            </Button>
            <Box sx={{marginBottom: '40px'}}>
                <BannerMessage
                    show={user.cb_item_price_id?.includes('demo')}
                    setHide={() => null}
                    showClose={false}
                    title="Upgrade to a paid plan to unlock all features."
                    message="You are currently using a demo account."
                />
            </Box>
            <Typography mx="12%" my={2} variant="headline" size="large">
                Manage Samples
            </Typography>
            <Typography mx="12%" variant="body" size="medium">
                View, add, and manage the samples associated with this experiment.
                {/* Utilize the flag function to mark samples to be excluded from analyses by default. You can choose to include them during analysis setup. Reasons you may want to flag samples could include: poor quality, outliers as determined through visualization (as in a PCA plot), and other idiosyncrasies that may arise.*/}
            </Typography>
            <Box mx="12%" my={4} sx={{bgcolor: 'surface-container-lowest', borderRadius: '15px'}} py={1} px={4}>
                {!addingSample && !isDuplicationStarted && (
                    <ButtonRow
                        areSelectedSamples={!!selectedSamplesArray.length}
                        addClick={setAddingSample}
                        onDelete={deleteCallback}
                        onSearchChange={handleSearchChange}
                        setDuplicationStarted={setDuplicationStarted}
                        setShowFilters={setShowFilters}
                        showFilters={showFilters}
                    />
                )}
                <Popper open={showFilters !== null} anchorEl={showFilters}>
                    <FilterList
                        initialList={filteredSamples}
                        setFilteredList={setFilteredList}
                        filteredList={filteredList}
                        setShowFilters={setShowFilters}
                    />
                </Popper>
                <ManageSamplesTable
                    data={filteredList || filteredSamples}
                    exp={experiment || demoExperiments[0]}
                    adding={addingSample}
                    setAdding={setAddingSample}
                    reFetchSamples={reFetchSamples}
                    setDeleteCallback={setDeleteCallback}
                    handleSelectAllClick={handleSelectAllClick}
                    handleSelectRow={handleSelectRow}
                    selectAllChecked={selectAllChecked}
                    selectedSamples={selectedSamples}
                    selectedSamplesArray={selectedSamplesArray}
                    isDuplicationStarted={isDuplicationStarted}
                    handleRemoveSample={handleRemoveSample}
                    setDuplicationStarted={setDuplicationStarted}
                />
            </Box>
        </PageContainer>
    );
}

export default ManageSamples;
