import React from 'react';
import {styled} from '@mui/material/styles';
import {Typography, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow} from '@mui/material';
import {CheckCircle, ArrowForward} from '@mui/icons-material';

const SamplesReviewBodyStyled = styled('div')({
    padding: '4%'
});

const CheckCircleStyled = styled(CheckCircle)({
    width: 60,
    height: 60
});

const ArrowForwardStyled = styled(ArrowForward)({
    width: 60,
    height: 60
});

const ContainerStyled = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

const TableContainerStyled = styled(TableContainer)({
    maxWidth: '330px',
    border: '1px solid #CAC4CF',
    borderColor: 'outline-variant',
    borderRadius: '5px',
    margin: '3% 2%'
});

const ButtonStyled = styled(Button)({
    width: '100%',
    marginTop: '20px'
});

interface SamplesReviewProps {
    samplesToReview: any;
    onClose?: () => void;
}

export const SamplesReview: React.FC<SamplesReviewProps> = ({samplesToReview, onClose}) => {
    return (
        <SamplesReviewBodyStyled>
            <CheckCircleStyled />
            <Typography variant="headline" size="medium" mb={1}>
                {samplesToReview?.changedSamples?.length} Sample(s) updated
            </Typography>
            <Typography variant="body" size="large">
                Value labels for the following samples have been changed:
            </Typography>
            <ContainerStyled>
                <TableContainerStyled>
                    <Table>
                        <TableHead sx={{bgcolor: 'surface-container'}}>
                            <TableCell width={'50%'} sx={{padding: '7px 13px'}}>
                                SAMPLE NAME
                            </TableCell>
                            <TableCell width={'35%'} sx={{padding: '7px 13px'}}>
                                {samplesToReview.key}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {samplesToReview.changedSamples.map((sample: any, i: number) => (
                                <TableRow key={i}>
                                    <TableCell width={'50%'} sx={{padding: '7px 13px'}}>
                                        {sample.name}
                                    </TableCell>
                                    <TableCell width={'35%'} sx={{padding: '7px 13px'}}>
                                        {samplesToReview.oldValue}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainerStyled>

                <ArrowForwardStyled />

                <TableContainerStyled>
                    <Table>
                        <TableHead sx={{bgcolor: 'surface-container'}}>
                            <TableCell width={'50%'} sx={{padding: '7px 13px'}}>
                                SAMPLE NAME
                            </TableCell>
                            <TableCell width={'35%'} sx={{padding: '7px 13px'}}>
                                {samplesToReview.key}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {samplesToReview.changedSamples.map((sample: any, i: number) => (
                                <TableRow>
                                    <TableCell width={'50%'} sx={{padding: '7px 13px'}}>
                                        {sample.name}
                                    </TableCell>
                                    <TableCell width={'35%'} sx={{padding: '7px 13px'}}>
                                        {samplesToReview.newValue}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainerStyled>
            </ContainerStyled>

            <Button onClick={onClose} variant="contained" fullWidth>
                Close
            </Button>
        </SamplesReviewBodyStyled>
    );
};
