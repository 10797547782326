import {
    KeyboardArrowUp,
    KeyboardArrowDown,
    ArrowRightAlt,
    Edit,
    ErrorOutline,
    BorderColor,
    CheckCircle,
    CheckBox,
    CheckCircleOutline,
    Refresh,
    Replay,
    ErrorSharp
} from '@mui/icons-material';
import {
    TableRow,
    TableCell,
    IconButton,
    Checkbox,
    TextField,
    MenuItem,
    Select,
    Collapse,
    Table,
    TableHead,
    Button,
    TableBody,
    Typography,
    Box,
    InputAdornment
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import EditMetaForm from '../../../ManageSamples/EditMetaForm';
import Popup from '../../../../components/popup';
import FileUploadBox from '../../../ManageSamples/FileUploadBox';
import {getAppConfig} from '../../../../utils/config';
import {useAuth0} from '@auth0/auth0-react';
import {GradientCircularProgress} from '../../SamplesProgModal';

export default function Row(props: any) {
    const {row, setSuccess, globalValues, errors, exp, selected, selectRow, fetchSamples, editSample} = props;
    const {getAccessTokenSilently} = useAuth0();
    const {apiHost} = getAppConfig();

    const [open, setOpen] = useState(false);
    const [retryUpload, setRetryUpload] = useState<any>(null);
    const [editMeta, setEditMeta] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [uploading, setUploading] = useState(false);
    const [finished, setFinished] = useState<any>([]);
    const [editState, setEditState] = useState<{[key: string]: string}>({});

    const errorMessages: Record<string, string> = {
        missingFiles: `This sample is missing files. Add (${exp?.files_per_sample - row?.files.length}) files to resolve this issue.`,
        duplicateName:
            'This Sample name has the same as another sample in your experiment. Please give this sample a unique name or delete it.',
        fileStatus: 'There was an error uploading your file. Please try uploading it again.'
    };

    const handleChange = (identifier: string, newValue: string) => {
        setEditState({[identifier]: newValue});
    };

    const handleSaveChanges = (key: any) => {
        if (editState[key] !== row[key] && editState[key] !== row.other_categories[key]) {
            if (key === 'name') {
                row.name = editState.name;
            } else {
                row.other_categories[key] = editState[key];
            }
            editSample(row);
            setEditState({});
            setSuccess(true);
        }
    };

    const getPresignedUrl = async (sampleId: any, fileId: any, filename: any) => {
        const response = await fetch(`${apiHost}/experiment/${exp.id}/sample/${sampleId}/file/${fileId}/get-presigned-url`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`
            }
        });
        const data = await response.json();
        return {url: data.url, filename};
    };

    const uploadFile = async (url: any, file: any) => {
        return fetch(url, {
            method: 'PUT',
            headers: {'Content-Type': 'text/plain'},
            body: file
        })
            .then(data => {
                setFinished((state: any) => [file?.name, ...state]);
            })
            .catch(err => {
                console.error('Error uploading file:', err);
            });
    };

    const formattedFiles = selectedFiles.map((obj: any) => {
        const filename = obj.name;
        const filenameSplit = filename.split('_');
        const laneNumber = filenameSplit.length >= 5 ? filenameSplit[filenameSplit.length - 3][3] : '1';
        const readNumber =
            filenameSplit.length >= 5 ? filenameSplit[filenameSplit.length - 2][1] : filenameSplit.length === 2 ? filenameSplit[1][0] : '1';
        return {
            name: filename,
            original_size: obj.size,
            strandedness_seq_sense: exp.sequencing_sense || '',
            strandedness_seq_end: exp.sequencing_read_type && exp.sequencing_read_type.toLowerCase(),
            interleaved: false,
            status: 'unknown',
            lane_number: laneNumber,
            read_number: readNumber,
            sample_id: row.id
        };
    });

    const addFileToSample = async (fileObject: any) => {
        const response = await fetch(`${apiHost}/experiment/${exp.id}/sample/${row.id}/file/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`
            },
            body: JSON.stringify(fileObject)
        });
        const fileRec = await response.json();
        const fileUrl = await getPresignedUrl(row.id, fileRec.id, fileRec.name);
        const fileObj = await selectedFiles.find((f: any) => f.name === fileUrl.filename);
        await uploadFile(fileUrl.url, fileObj);
    };

    async function processAllFiles() {
        setUploading(true);
        let promises;
        if (!!retryUpload) {
            promises = formattedFiles.map(async (f: any) => {
                const fileUrl = await getPresignedUrl(row.id, retryUpload.id, retryUpload.name);

                const fileObj = selectedFiles[0];
                await uploadFile(fileUrl.url, fileObj);
            });
        } else {
            promises = formattedFiles.map((obj: any) => addFileToSample(obj));
        }
        const results = await Promise.all(promises).then(d => {
            setRetryUpload(null);
            setSuccess(true);
            setUploading(false);
            fetchSamples();
        });
        return results;
    }

    useEffect(() => {
        Object.keys(errors).map((m: any) => (errors[m].includes(row.id) ? setOpen(true) : ''));
    }, [errors]);

    return (
        <>
            {/* Error Messages Box for Row with Errors */}
            <TableRow>
                <TableCell colSpan={8}>
                    {Object.keys(errors).map(
                        (m: any) =>
                            errors[m].includes(row.id) && (
                                <Box
                                    sx={{
                                        px: 2,
                                        py: 1,
                                        my: '1px',
                                        borderRadius: '4px',
                                        bgcolor: 'error-container',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start'
                                    }}>
                                    <Typography display={'flex'} alignItems={'center'} gap={2}>
                                        <ErrorOutline fontSize="small" /> {errorMessages[m]}
                                    </Typography>
                                </Box>
                            )
                    )}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell align="right" padding="checkbox">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={() => selectRow(row.id)} size="small" />
                </TableCell>
                <TableCell>
                    <TextField
                        sx={{minWidth: '140px'}}
                        value={editState.name !== undefined ? editState.name : row.name}
                        onChange={e => handleChange('name', e.target.value)}
                        onBlur={() => handleSaveChanges('name')}
                    />
                </TableCell>
                {globalValues &&
                    Object.entries(globalValues)?.map(([key, options]) => {
                        return (
                            <TableCell key={key}>
                                <Select
                                    sx={{height: '30px', padding: '1px 2px'}}
                                    fullWidth
                                    value={editState[key] ? editState[key] : row.other_categories[key]}
                                    onChange={e => handleChange(key, e.target.value)}
                                    onBlur={() => handleSaveChanges(key)}>
                                    {(options as string[])?.sort().map((option: string) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        );
                    })}
            </TableRow>
            <TableCell sx={{p: '5px'}} colSpan={5 + (!globalValues ? 2 : Object.keys(globalValues)?.length)}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table size="small" sx={{mt: 1}}>
                        <TableHead>
                            <TableRow sx={{bgcolor: 'surface-container-high'}}>
                                <TableCell>Sample ID</TableCell>
                                <TableCell>Seq-end</TableCell>
                                <TableCell>Sense</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableRow sx={{bgcolor: 'surface-container-low'}}>
                            <TableCell>{'S-' + row?.sample_num}</TableCell>
                            <TableCell>{exp?.sequencing_read_type || row?.files?.[0]?.strandedness_seq_end}</TableCell>
                            <TableCell>{exp?.sequencing_sense || 'unstranded'}</TableCell>
                        </TableRow>
                    </Table>
                    <Table size="small" sx={{mt: '3px'}}>
                        <TableHead>
                            <TableRow sx={{bgcolor: 'surface-container-high'}}>
                                <TableCell> </TableCell>
                                <TableCell>File ID</TableCell>
                                <TableCell>File Name</TableCell>
                                <TableCell sx={{pl: 3}}>Status</TableCell>
                                <TableCell>Lane Number</TableCell>
                                <TableCell>Read Number</TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>

                        {/* --- File Display for Sample  ---> */}
                        <TableBody sx={{bgcolor: 'surface-container-low'}}>
                            {row?.files?.map((fileEntry: any) => (
                                <TableRow key={fileEntry.id}>
                                    <TableCell align="right">
                                        {/* the download button below is disabled for now. uncomment to enable 
                         <CloudDownload sx={{ fontSize:'18px', color:'gray' }} /> */}
                                    </TableCell>
                                    <TableCell align="left">SM-{fileEntry.id.slice(0, 5)}</TableCell>
                                    <TableCell>{fileEntry.name}</TableCell>
                                    {/* ---- File upload Retry button & file upload popup ----> */}
                                    <TableCell align="left">
                                        {fileEntry.status === 'ready' ? (
                                            <Typography>
                                                <CheckCircleOutline color="disabled" sx={{fontSize: '18px'}} /> ready
                                            </Typography>
                                        ) : (
                                            <Button
                                                variant="text"
                                                sx={{mx: 0, px: 1}}
                                                startIcon={<ErrorOutline color="error" sx={{fontSize: '18px', mx: 0}} />}
                                                endIcon={<Replay sx={{mx: 0}} />}
                                                color="primary"
                                                onClick={() => setRetryUpload(fileEntry)}>
                                                {fileEntry.status}
                                            </Button>
                                        )}
                                    </TableCell>
                                    {/* ---- Files with no Metadata Display add Metadata warning and button Here ----> */}
                                    <TableCell align="center">
                                        {'00' + fileEntry?.lane_number || (
                                            <Button
                                                variant="text"
                                                startIcon={<ErrorOutline />}
                                                endIcon={<ArrowRightAlt />}
                                                color="error"
                                                onClick={e => setEditMeta(fileEntry)}>
                                                <Typography variant="label" size="medium - prominent">
                                                    Add Metadata
                                                </Typography>
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {fileEntry?.read_number || (
                                            <Button
                                                variant="text"
                                                startIcon={<ErrorOutline />}
                                                endIcon={<ArrowRightAlt />}
                                                color="error"
                                                onClick={e => setEditMeta(fileEntry)}>
                                                <Typography variant="label" size="medium - prominent">
                                                    Add Metadata
                                                </Typography>
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {' '}
                                        <Edit sx={{fontSize: '18px', cursor: 'pointer'}} onClick={e => setEditMeta(fileEntry)} />{' '}
                                    </TableCell>
                                </TableRow>
                            ))}

                            {/* --- File Upload Box Conditional Rendering For File Uploading ---> */}
                            <TableRow sx={{py: 0}}>
                                <TableCell colSpan={8}>
                                    <Box p={1} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                        {row?.files?.length < exp?.files_per_sample &&
                                            (!uploading ? (
                                                <>
                                                    <FileUploadBox
                                                        maxFiles={exp.files_per_sample - row.files.length}
                                                        selectedFiles={selectedFiles}
                                                        onFilesSelected={setSelectedFiles}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        disabled={selectedFiles?.length + row.files.length < exp.files_per_sample}
                                                        sx={{mt: 1, alignSelf: 'center', px: 2, py: 1}}
                                                        onClick={processAllFiles}>
                                                        Upload Files
                                                    </Button>
                                                </>
                                            ) : (
                                                <Box>
                                                    {selectedFiles?.map((f: any) => (
                                                        <Typography variant="body" size="medium">
                                                            {f.name}{' '}
                                                            {finished.includes(f.name) ? (
                                                                <CheckCircle />
                                                            ) : (
                                                                <GradientCircularProgress gradientId="gradient-add-files" />
                                                            )}{' '}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            ))}
                                        {row?.files?.length == exp?.files_per_sample && (
                                            <Typography
                                                align="center"
                                                border={'1px dashed'}
                                                borderColor={'outline-variant'}
                                                p={1}
                                                color={'outline-variant'}>
                                                You've reached the maximum number of files allowed for this sample.
                                            </Typography>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
            {/* ---- Retry File Upload Popup ----> */}
            <Popup isOpen={!!retryUpload}>
                <Box p={3}>
                    {!uploading ? (
                        <Box>
                            <Typography variant="title" size="large" p={1} mb={2}>
                                Select the File: {retryUpload?.name}
                            </Typography>
                            <FileUploadBox maxFiles={1} selectedFiles={selectedFiles} onFilesSelected={setSelectedFiles} />
                            {selectedFiles?.[0] && selectedFiles[0]?.name !== retryUpload?.name && (
                                <Typography variant="title" size="medium" color="error" px={2} mt={1}>
                                    {' '}
                                    <ErrorSharp fontSize="small" /> Please Choose the File with the Name Listed Above in Order to Proceed,
                                    or click cancel then edit the filename and click retry upload again.{' '}
                                </Typography>
                            )}
                            <Button
                                variant="contained"
                                size="small"
                                disabled={selectedFiles?.length < 1 || selectedFiles[0]?.name !== retryUpload?.name}
                                sx={{mt: 1, alignSelf: 'center', px: 2, py: 1}}
                                onClick={processAllFiles}>
                                Upload
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{mt: 1, mx: 1, alignSelf: 'center', px: 2, py: 1}}
                                onClick={() => setRetryUpload(null)}>
                                Cancel
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            {selectedFiles?.map((f: any) => (
                                <Typography variant="body" size="medium">
                                    {f.name}{' '}
                                    {finished.includes(f.name) ? (
                                        <CheckCircle />
                                    ) : (
                                        <GradientCircularProgress gradientId="gradient-add-files" />
                                    )}{' '}
                                </Typography>
                            ))}
                        </Box>
                    )}
                </Box>
            </Popup>
            <Popup isOpen={editMeta ? true : false}>
                {' '}
                <EditMetaForm file={editMeta} expId={exp?.id} sId={row?.id} refetch={fetchSamples} setEdit={setEditMeta} />{' '}
            </Popup>
        </>
    );
}
