import React from 'react';
import {Box, Typography, LinearProgress} from '@mui/material';
import {Timelapse, Memory} from '@mui/icons-material';
import WidgetBox from '../components/WidgetBox';

interface AccountStatsWidgetProps {
    current?: boolean;
    data: AccountStatsData;
    inWidgetBox?: boolean;
}

interface AccountStatsData {
    analysis_hours_current: number;
    analysis_hours_max: number;
    storage_current: number;
    storage_max: number;
}

interface ConditionalWrapperProps {
    inWidgetBox: boolean;
    wrapper: (children: React.ReactElement) => React.ReactElement;
    children: React.ReactElement | null;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({inWidgetBox, wrapper, children}) =>
    inWidgetBox && React.isValidElement(children) ? wrapper(children) : children;

interface StatsItemProps {
    iconComponent: React.ReactElement;
    title: string;
    currentVal: number;
    maxVal: number;
    units: string;
}

// const StatsItem: React.FC<StatsItemProps> = ({ iconComponent, title, currentVal, maxVal, units }) => {
//     return (
//         <Box display="flex" flexDirection="column" gap="6px">
//             <Box display="flex" flexDirection="row" gap="13px" alignItems="center">
//                 {iconComponent}
//                 <Typography variant="label" size="large" color="on-surface-variant">
//                     {title}
//                 </Typography>
//             </Box>
//             <Box display="flex" flexDirection="column" gap="0">
//                 <Box display="flex" flexDirection="row" alignItems="flex-start">
//                     <Typography variant="display" size="small" component="span" color="on-surface">
//                         {Math.round((currentVal / maxVal) * 100)}
//                     </Typography>
//                     <Typography variant="headline" size="small" component="span" color="on-surface">
//                         %
//                     </Typography>
//                 </Box>
//                 <Typography variant="label" size="medium" color="on-surface-variant">
//                     USED
//                 </Typography>
//             </Box>
//             <LinearProgress variant="determinate" value={(currentVal / maxVal) * 100} />
//             <Typography variant="body" size="small" color="on-surface-variant">
//                 {/* Todo: defaults to 10k. Remove this behavior before prod */}
//                 {`${currentVal} ${units} of ${Math.round(maxVal) / 1000}k hours used`}
//             </Typography>
//         </Box>
//     )
// }

const StatsItem: React.FC<StatsItemProps> = ({iconComponent, title, currentVal, maxVal, units}) => {
    return (
        <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" flexDirection="row" gap="13px" alignItems="center">
                <Box borderRadius="20px" display="flex" justifyContent="center" alignItems="center" bgcolor="black">
                    {iconComponent}
                </Box>
                <Typography variant="label" size="large" color="on-surface-variant">
                    {title}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap="0">
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                    <Typography variant="headline" size="small" component="span" color="black">
                        Unlimited
                    </Typography>
                </Box>
            </Box>
            <Typography variant="body" size="small" color="on-surface-variant">
                {/* Todo: defaults to 10k. Remove this behavior before prod */}
                {/*{`${currentVal} ${units} of ${Math.round(maxVal) / 1000}k hours used`}*/}
            </Typography>
        </Box>
    );
};

const AccountStatsWidget: React.FC<AccountStatsWidgetProps> = ({current = true, data, inWidgetBox = false}) => {
    return (
        <ConditionalWrapper inWidgetBox={inWidgetBox} wrapper={children => <WidgetBox title="Account">{children}</WidgetBox>}>
            <Box
                display="flex"
                flexDirection="column"
                gap="24px"
                flex="1"
                // sx={{
                //     ...(current ? {} : { opacity: "0.3" })
                // }}
            >
                <StatsItem
                    iconComponent={<Timelapse sx={{color: '#fff', fontSize: '16px'}} />}
                    title="Analysis hours"
                    currentVal={data.analysis_hours_current}
                    maxVal={data.analysis_hours_max}
                    units="hours"
                />
                <StatsItem
                    iconComponent={<Memory sx={{color: '#fff', fontSize: '16px'}} />}
                    title="Storage"
                    currentVal={data.storage_current}
                    maxVal={data.storage_max}
                    units="GB"
                />
            </Box>
        </ConditionalWrapper>
    );
};

export default AccountStatsWidget;
