import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {Button} from '@mui/material';
import {FileCopy} from '@mui/icons-material';

export const Container = styled('div')({
    width: '100%',
    padding: '20px',
    borderRadius: '20px',
    outline: '1px dashed #CAC4D0'
});

interface CopyTextProps {
    text: string;
}

export const CopyText: React.FC<CopyTextProps> = ({text}) => {
    const [isCopied, setIsCopied] = useState(false);
    const buttonText = isCopied ? 'Copied!' : 'Copy text';

    const handleCopyClick = () => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setIsCopied(true);
            })
            .catch(err => {
                console.log('Failed to copy text: ', err);
            });
    };

    return (
        <Container sx={{bgcolor: 'surface-container-low'}}>
            <Button onClick={handleCopyClick} variant="outlined">
                <FileCopy sx={{fontSize: '15px', mr: 1}} />
                {buttonText}
            </Button>

            <div id="textToCopy" dangerouslySetInnerHTML={{__html: text}} />
        </Container>
    );
};
