import React from 'react';
import {createRoot} from 'react-dom/client';
import {Auth0Provider} from '@auth0/auth0-react';
import {BrowserRouter} from 'react-router-dom';
import {getAppConfig} from './utils/config';
import App from './App';

const container = document.getElementById('root');

const root = createRoot(container!);

const config = getAppConfig();
if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
}
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0Provider
                domain={config.auth0Domain}
                clientId={config.auth0ClientId}
                authorizationParams={{
                    audience: config.auth0Audience,
                    redirect_uri: window.location.origin
                }}>
                <App />
            </Auth0Provider>
        </BrowserRouter>
    </React.StrictMode>
);
