import React from 'react';
import {Box} from '@mui/material';
import {EnhancedTable} from './EnhancedTable';

const SelectVariables = (props: {formData: any; setFormData: any; handleNext: any; groupLevels: any; error: any; setError: any}) => {
    const {formData, setFormData, handleNext, groupLevels, error, setError} = props;

    return (
        <Box>
            <EnhancedTable
                setFormData={setFormData}
                formData={formData}
                handleNext={handleNext}
                groupLevels={groupLevels}
                error={error}
                setError={setError}
            />
        </Box>
    );
};

export default SelectVariables;
