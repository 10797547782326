import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ModalDemoPlan from './ModalDemoPlan';
import {useNavigate} from 'react-router-dom';
import {useBff} from '../../utils/config';
import {useTheme} from '@mui/material/styles';
import logo from '../../assets/logo.png';
import useMediaQuery from '@mui/material/useMediaQuery';

interface PlanSelectionProps {
    setPlan: (plan: Plan) => void;
}

const PlanSelection: React.FC<PlanSelectionProps> = ({setPlan}) => {
    const [demoModalOpen, setDemoModalOpen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [data, error, isLoading] = useBff('GET', 'subscriptions/plans');

    if (isLoading) return <div>Loading...</div>;
    console.log(theme.palettes);
    console.log('plans data', data);
    return (
        <Box height="100vh">
            <Box
                width={isMobile ? '100%' : '1144px'}
                paddingTop="100px"
                paddingLeft={isMobile ? '20px' : 0}
                paddingRight={isMobile ? '20px' : 0}
                margin={isMobile ? '0' : '0 auto'}>
                <img src={logo} alt="logo" width="200px" />
                <Typography variant="headline" size="large" marginTop="44px" {...(isMobile ? {fontSize: '28px'} : {})}>
                    Select your level of access.
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                gap="44px"
                margin={isMobile ? '50px 0 0 0' : '50px auto 0 auto'}
                width="fit-content"
                minWidth={isMobile ? '0' : '755px'}>
                {data
                    ?.sort((planA: any, planB: any) => planB.price - planA.price)
                    .map((plan: any) => ({
                        id: plan.id,
                        type: plan.price > 0 ? 'paid' : 'demo',
                        title: plan.external_name,
                        price_usd: (plan.price / 100).toFixed(2),
                        price_display: plan.price > 0 ? `$${Math.round(plan.price / 100)}` : 'FREE',
                        description: plan.description,
                        features: plan.metadata?.features || [],
                        period_unit: plan.period_unit,
                        period: plan.period
                    }))
                    .filter((plan: any) => plan.period_unit === 'month' && plan.period === 1)
                    .map((plan: any, index: number) => (
                        <Box
                            key={index}
                            width={isMobile ? '100%' : '550px'}
                            overflow="hidden"
                            display="flex"
                            borderRadius="20px"
                            // bgcolor={plan.type === "paid" ? "#000" : "surface-container-lowest"}
                            flexDirection="column">
                            {plan.type === 'paid' && (
                                <Box
                                    bgcolor="primary-fixed-dim"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="24px"
                                    sx={{background: 'linear-gradient(90deg, #7C57F4 0%, #F7218C 100%)'}}>
                                    <Typography variant="title" size="small" color="on-primary" lineHeight="24px">
                                        SPECIAL EARLY ADOPTER FEATURES
                                    </Typography>
                                </Box>
                            )}

                            <Box
                                padding="44px 30px 0 36px"
                                display="flex"
                                gap="0px"
                                flexDirection="column"
                                bgcolor={plan.type === 'paid' ? '#000' : 'surface-container-lowest'}>
                                <Typography
                                    variant="headline"
                                    size="small"
                                    color={plan.type === 'paid' ? 'primary.contrastText' : 'on-surface'}>
                                    {plan.title}
                                </Typography>
                                <Box display="flex" alignItems="flex-end">
                                    <Typography
                                        variant={plan.type === 'paid' ? 'display' : 'headline'}
                                        size={plan.type === 'paid' ? 'medium' : 'large'}
                                        color={plan.type === 'paid' ? 'primary.contrastText' : 'on-surface'}>
                                        {plan.price_display}
                                    </Typography>
                                    {plan.type === 'paid' && (
                                        <Typography
                                            variant="title"
                                            size="small"
                                            color="surface-container-lowest"
                                            textTransform={'capitalize'}
                                            margin="0 0 6px 5px">
                                            {`User / ${plan.period_unit}`}
                                        </Typography>
                                    )}
                                </Box>

                                <Typography
                                    variant="title"
                                    size="medium"
                                    marginTop="12px"
                                    color={plan.type === 'paid' ? 'on-primary' : 'on-surface'}>
                                    {plan.description}
                                </Typography>
                                {plan.features && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        flex="1"
                                        gap="3px"
                                        marginTop="24px"
                                        paddingBottom={plan.type === 'paid' ? 0 : '35px'}>
                                        {plan.features.map((feature: any, index: number) => (
                                            <Box display="flex" gap="8px">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9"
                                                    height="24px"
                                                    style={{marginTop: '6px'}}
                                                    overflow="visible"
                                                    viewBox="0 0 9px 9px"
                                                    fill="none">
                                                    <circle height="24px" cx="4" cy="4.51172" r="4" fill="#EADDFF" />
                                                </svg>
                                                {feature.left && (
                                                    <Typography
                                                        variant="body"
                                                        size="large"
                                                        sx={{
                                                            color: plan.type === 'paid' ? theme.palette.primary.contrastText : 'on-surface',
                                                            textDecoration: 'line-through'
                                                        }}>
                                                        {feature.left}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    variant="body"
                                                    size="large"
                                                    sx={{
                                                        color: plan.type === 'paid' ? theme.palette.primary.contrastText : 'on-surface'
                                                    }}>
                                                    {feature.right}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Box>

                            <Box
                                padding="14px 36px 29px 36px"
                                display="flex"
                                flexDirection="column"
                                bgcolor={plan.type === 'paid' ? '#000' : 'surface-container-lowest'}
                                borderRadius="0 0 20px 20px">
                                <Button
                                    sx={{
                                        width: '100%',
                                        height: '52px',
                                        backgroundColor: plan.type === 'paid' ? 'surface-container-low' : 'primary.main',
                                        color: plan.type === 'paid' ? 'on-surface' : 'primary.contrastText',
                                        '&:hover': {
                                            backgroundColor: plan.type === 'paid' ? 'surface-container-low' : 'primary.main'
                                        }
                                    }}
                                    onClick={() => {
                                        if (plan.type === 'demo') {
                                            setDemoModalOpen(true);
                                        } else {
                                            setPlan(plan);
                                        }
                                    }}>
                                    Select and Continue
                                </Button>
                            </Box>
                            {plan.type === 'demo' && (
                                <ModalDemoPlan open={demoModalOpen} setClose={() => setDemoModalOpen(false)} plan={plan} />
                            )}
                        </Box>
                    ))}
            </Box>
            <Box
                margin="44px auto 0 auto"
                width={isMobile ? '100%' : '1144px'}
                display="flex"
                padding={isMobile ? '0 20px 150px 20px' : 0}
                flexDirection="column"
                gap={isMobile ? '20px' : '9px'}>
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                    <Typography variant="body" size="large" color="on-surface">
                        Interested in additional licenses or account options, including Enterprise plans?
                    </Typography>
                    <Typography
                        variant="body"
                        size="large"
                        color="on-surface"
                        sx={{
                            textDecoration: 'underline',
                            marginLeft: '5px'
                        }}
                        component="a"
                        href="#">
                        Reach out to our team.
                    </Typography>
                </Box>
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                    <Typography variant="body" size="medium" color="on-surface">
                        Need more information regarding our account options?
                    </Typography>
                    <Typography
                        variant="body"
                        size="medium"
                        color="on-surface"
                        sx={{
                            textDecoration: 'underline',
                            marginLeft: '5px'
                        }}
                        component="a"
                        href="#">
                        Review our Plans & Pricing page.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default PlanSelection;
