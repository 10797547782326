import {VisData} from '../../../utils/database';

import {PCA} from 'ml-pca';

export function getPCAPlot({data, geneList}: {data: VisData; geneList: string[]}) {
    if (geneList.length === 0) {
        return {
            layout: {},
            data: []
        };
    }
    const columns: {[k: string]: number[]} = {};
    data.expressionCount.data.forEach(d => {
        if (!columns[d.sample_name]) {
            columns[d.sample_name] = [];
        }
        const genes: any[] = [];
        d.genes.forEach((g: any) => {
            if (data.visSessionInfo.data.gene_list_map[geneList[0]][g.gene]) {
                genes.push(parseFloat(g.count));
            }
        });
        columns[d.sample_name].push(...genes);
    });
    const pca = new PCA(Object.values(columns), {
        scale: true
    });
    const pc = pca.predict(Object.values(columns));

    const traces = Object.keys(columns).map((k, i) => {
        return {
            x: [pc.get(0, i)],
            y: [pc.get(1, i)],
            name: k,
            mode: 'markers',
            type: 'scattergl',
            legendgroup: k
        };
    });
    return {
        layout: {
            showlegend: false,
            xref: 'paper',
            yref: 'paper',
            xanchor: 'center',
            yanchor: 'top',
            margin: {l: 5, r: 15, t: 50, b: 5}
        },
        traces: traces
    };
}
