import React, {useState} from 'react';
import {Box, Typography, Divider, TextField} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingButton from '@mui/lab/LoadingButton';
import {useAuth0} from '@auth0/auth0-react';
import {getAppConfig} from '../utils/config';

interface CostSummaryProps {
    plan?: Plan;
    code?: string;
    setCode?: (code: string) => void;
    codeValid?: boolean;
    setCodeValid?: (valid: boolean) => void;
    discountPercentage: number;
    setDiscountPercentage: (discount: number) => void;
}

const CostSummaryInitial: React.FC<CostSummaryProps> = ({
    plan,
    code,
    setCode,
    codeValid,
    setCodeValid,
    discountPercentage,
    setDiscountPercentage
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [checkingCode, setCheckingCode] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const {getAccessTokenSilently} = useAuth0();
    const {apiHost} = getAppConfig();

    const handleCouponSumbit = async () => {
        setCheckingCode(true);
        console.log('Checking coupon code...');
        try {
            const response = await fetch(`${apiHost}/subscriptions/check-coupon`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await getAccessTokenSilently()}`
                },
                body: JSON.stringify({code})
            });
            console.log('after fetch');
            console.log('response', response);
            if (response.ok) {
                const data = await response.json();
                console.log('data ok', data);
                setCodeValid!(true);
                setDiscountPercentage(data.cbResponse.coupon.discount_percentage);
            } else {
                setCodeValid!(false);
                const data = await response.json();
                if (!data.valid) {
                    console.error('Coupon code is not valid');
                } else {
                    console.error('Failed to check coupon code (1)');
                }
                setCodeValid!(false);
            }
            setShowMessage(true);
        } catch (error) {
            // when the server is not running or the request fails
            console.error('Failed to check coupon code (2)', error);
            setCheckingCode(false);
            return;
        }
        setCheckingCode(false);
    };

    return (
        <Box display="flex" flexDirection="column" gap="30px">
            <Box bgcolor="surface-container-lowest" width={isMobile ? '100%' : '370px'} borderRadius="15px" marginTop="8px" padding="20px">
                <Box display="flex" flexDirection="column" padding="8px 26px 0px 18px" height="65px">
                    <Typography variant="title" size="large" color="on-surface">
                        {plan?.title}
                    </Typography>
                    <Typography variant="title" size="large" color="on-surface" textTransform={'capitalize'}>
                        {`${plan?.period_unit}ly`}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="23px"
                    padding="26px 32px 27px 18px"
                    marginTop="12px"
                    bgcolor="surface-container"
                    borderRadius="8px">
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body" size="medium" color="on-surface" flex="1">
                            Subscription, 1 Seat
                        </Typography>
                        <Typography color="on-surface" variant="body" size="medium" sx={{textAlign: 'right', width: '85px'}}>
                            {`$${plan?.price_usd}`}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body" size="medium" color="on-surface" flex="1">
                            Subtotal
                        </Typography>
                        <Typography
                            color="on-surface"
                            variant="body"
                            size="medium"
                            sx={{fontSize: '14px', textAlign: 'right', width: '85px'}}>
                            {`$${plan?.price_usd}`}
                        </Typography>
                    </Box>
                    {discountPercentage > 0 && (
                        <Box display="flex" flexDirection="row">
                            <Typography variant="body" size="medium" color="on-surface" flex="1">
                                Discount
                            </Typography>
                            <Typography
                                color="#080"
                                variant="body"
                                size="medium"
                                sx={{fontSize: '14px', textAlign: 'right', width: '85px'}}>
                                {`-$${(plan!.price_usd * (discountPercentage / 100)).toFixed(2)}`}
                            </Typography>
                        </Box>
                    )}
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body" size="large" color="on-surface" flex="1">
                            Total due today
                        </Typography>
                        <Typography variant="body" size="large" color="on-surface" width="85px" align="right">
                            {`$${plan!?.price_usd - plan!.price_usd * (discountPercentage / 100)}`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box bgcolor="surface-container-lowest" padding="15px 20px" borderRadius="15px" display="flex" flexDirection="column" gap="5px">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <TextField
                        variant="outlined"
                        label="Coupon Code"
                        value={code}
                        size="small"
                        disabled={codeValid}
                        onChange={e => {
                            setShowMessage(false);
                            setCode!(e.target.value);
                        }}
                        sx={{
                            fontWeight: '400',
                            fontSize: '16px',
                            letterSpacing: '0.5px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E0DCEA'
                            }
                        }}
                    />
                    <LoadingButton
                        variant="contained"
                        disabled={!code?.length || codeValid}
                        loading={checkingCode}
                        onClick={handleCouponSumbit}
                        sx={{width: '100px', height: '35px'}}>
                        Apply
                    </LoadingButton>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    {showMessage && (
                        <>
                            {codeValid ? (
                                <Typography variant="body" size="medium" color="#080">
                                    Code Valid
                                </Typography>
                            ) : (
                                <Typography variant="body" size="medium" color="#800">
                                    Code Not Valid
                                </Typography>
                            )}
                        </>
                    )}
                    {codeValid && (
                        <Typography
                            variant="body"
                            size="medium"
                            color="#000"
                            onClick={() => {
                                setCode!('');
                                setCodeValid!(false);
                                setShowMessage(false);
                                setDiscountPercentage(0);
                            }}
                            sx={{textDecoration: 'underline', cursor: 'pointer'}}>
                            Remove
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CostSummaryInitial;
