import {useCallback, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {getAppConfig, setAuthHeader} from './config';

export interface IDiffExpResults {
    id?: number; // Primary key. Optional (autoincremented)
    comparison: string;
    basemean: number;
    ensembl_id: string;
    foldchange: number;
    gene_name: number;
    lfcse: number;
    log2foldchange: number;
    padj: number;
    pvalue: number;
    stat: number;
    Significance: string;
    '-log10(pvalue)': number;
    '-log10(padj)': number;
    log2foldchangeList: number[];
    log10pvalueList: number[];
    log10padjList: number[];
}
export type VisData = {
    comparisons: {
        displayName: string;
        data: {comparison: string}[];
        error: Error;
        isLoading: boolean;
    };
    diffExpResults: {
        displayName: string;
        data: {[key: string]: IDiffExpResults[]};
        error: Error;
        isLoading: boolean;
    };

    geneOntologyOutput: {
        displayName: string;
        data: {[k: string]: any}[];
        error: Error;
        isLoading: boolean;
    };
    keggOutPut: {
        displayName: string;

        data: {[k: string]: any}[];
        error: Error;
        isLoading: boolean;
    };
    visSessionInfo: {
        displayName: string;
        data: {[k: string]: any};
        error: Error;
        isLoading: boolean;
    };
    expressionCount: {
        displayName: string;
        data: {[k: string]: any}[];
        error: Error;
        isLoading: boolean;
    };
};

type ReportdDeff = {
    name: string;
    displayName: string;
    process?: Function;
};

export function useVisData(reports: ReportdDeff[], runId: string) {
    let initState = useCallback(
        function () {
            return reports.reduce(
                (a, c) => {
                    if (!a[c.name]) {
                        a[c.name] = {
                            displayName: c.displayName,
                            error: undefined,
                            data: [],
                            isLoading: true
                        };
                    }
                    return a;
                },
                {} as {[k: string]: any}
            );
        },
        [reports]
    );
    const [state, setState] = useState(initState());
    const {getAccessTokenSilently} = useAuth0();
    const config = getAppConfig();
    const makeReq = useCallback(
        async function (key: string, path: string, signal: AbortSignal, process?: Function) {
            const token = await getAccessTokenSilently();
            return fetch(config.buildRoute(path), {
                method: 'GET',
                signal,
                headers: setAuthHeader(token)
            })
                .then(data => {
                    return data.json();
                })
                .then(data => {
                    const obj = state[key];
                    console.log(obj);
                    if (process) {
                        try {
                            obj.data = process(data);
                        } catch (e) {
                            console.error('Error data preprocess:', e);
                            obj.data = data;
                        }
                    } else {
                        obj.data = data;
                    }
                    obj.isLoading = false;
                    obj.error = undefined;
                    state[key] = obj;
                    setState({...state});
                })
                .catch(err => {
                    const obj = state[key];
                    obj.error = err;
                    obj.isLoading = false;
                    state[key] = obj;
                    setState({...state});
                });
        },
        [config, getAccessTokenSilently, state]
    );

    useEffect(() => {
        console.time('get data');
        const aborts: AbortController[] = [];
        const ps = reports.map(r => {
            const ac = new AbortController();
            aborts.push(ac);
            const path = `run/${runId}/${r.name}`;
            return makeReq(r.name, path, ac.signal, r.process);
        });
        Promise.all(ps).then(() => {
            console.log('Got all data, took: ');
            console.timeEnd('get data');
        });
        // return () => {
        //   aborts.forEach((a) => {
        //     if (!a.signal.aborted) {
        //       a.abort("leaving page");
        //     }
        //   });
        // };
    }, []);

    return state;
}
