import {GeneralGraphProps} from '../../../types/GraphTypes';

export * from './HeatmapController';
export interface HeatmapProps extends GeneralGraphProps {}

export const heatmapConfig: HeatmapProps = {
    xTitle: 'xValue',
    title: 'Heatmap Plot',
    xTitlePos: 0,
    yTitlePos: 0.9,
    yTitle: '',
    fontSizeTitle: 20,
    fontSizeX: 12,
    fontSizeY: 12,
    width: 800,
    height: 500,

    showGrid: false,
    setDefaults: true,
    mLeft: 0
};
