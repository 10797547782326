import React from 'react';
import {Typography, Button} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {styled} from '@mui/material/styles';

export const ContainerStyled = styled('div')({
    padding: '5%'
});

export const ActionBarStyled = styled('div')({
    display: 'flex',
    marginTop: 30,
    alignItems: 'center',
    justifyContent: 'center'
});

interface BeforeYouBeginProps {
    onClose?: () => void;
}

export const BeforeYouBegin: React.FC<BeforeYouBeginProps> = ({onClose}) => {
    return (
        <ContainerStyled sx={{color: 'on-surface-variant'}}>
            <Typography variant="headline" size="medium">
                Critical aspects of your samples to know before you begin.
            </Typography>
            <Typography variant="title" size="medium" my={3}>
                Before you start...
            </Typography>
            <Typography variant="body" size="medium">
                In order for your data to be processed, it needs to have been sequenced in the same platform, by the same company, in the
                same run. Trovomics will not account for differentiators, known as “batch effects,” if your samples were run separately.
            </Typography>
            <Typography variant="body" size="medium" py={3} mb={2}>
                Not sure if your files meet these requirements? Reach out to your sequencing provider to confirm.
            </Typography>

            <Button onClick={onClose} variant="contained" fullWidth color="primary">
                I understand
            </Button>
            <ActionBarStyled>
                <Checkbox inputProps={{'aria-label': 'Checkbox demo'}} defaultChecked /> Don’t show this again
            </ActionBarStyled>
        </ContainerStyled>
    );
};
