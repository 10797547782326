import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import Slider, {SliderProps, SliderThumb} from '@mui/material/Slider';
import HeightIcon from '@mui/icons-material/Height';
import Tooltip from '@mui/material/Tooltip';
interface TrovoSliderThumbProps extends React.HTMLAttributes<unknown> {}
function TrovoSliderGen(props: TrovoSliderThumbProps) {
    const {children, ...other} = props;
    return <SliderThumb {...other}>{children}</SliderThumb>;
}
// the tip on down/up is a little finicky, but probs alright for now
export function TrovoSliderThumbUpDown(props: TrovoSliderThumbProps) {
    const {children, ...other} = props;
    const [open, setOpen] = useState(false);
    return (
        <Tooltip
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
            title={'Use to move up and down'}
            arrow
            placement={'left'}>
            <SliderThumb {...other} onMouseDown={() => setOpen(false)} onMouseUp={() => setOpen(true)}>
                {children}
                <HeightIcon />
            </SliderThumb>
        </Tooltip>
    );
}

// the tip on down/up is a little finicky, but probs alright for now
export function TrovoSliderThumbLeftRight(props: TrovoSliderThumbProps) {
    const {children, ...other} = props;
    const [open, setOpen] = useState(false);
    return (
        <Tooltip
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            title={'Use to move right and left'}
            arrow
            open={open}
            placement={'bottom'}>
            <SliderThumb {...other} onMouseDown={() => setOpen(false)} onMouseUp={() => setOpen(true)}>
                {children}
                <HeightIcon style={{transform: 'rotate(90deg)'}} />
            </SliderThumb>
        </Tooltip>
    );
}
const StlyedSlider = styled((props: SliderProps) => <Slider {...props} />)(({theme}) => ({
    height: 3,
    width: 3,
    padding: '0 10px 0 3px',
    '& .MuiSlider-track': {
        border: 'none',
        backgroundColor: theme.schemes.light.secondaryContainer
    },
    '& .MuiSlider-rail': {
        border: 'none',
        backgroundColor: theme.schemes.light.primary
    },
    '& .MuiSlider-thumb': {
        height: 0,
        width: 0,
        color: theme.schemes.light.onSurface,
        backgroundColor: '#fff',
        // border: "1px solid currentColor",
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit'
        },
        '&:before': {
            display: 'none'
        }
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: theme.schemes.light.outline,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': {display: 'none'},
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
        },
        '& > *': {
            transform: 'rotate(45deg)'
        }
    }
}));

export function PosSlider(
    props: SliderProps & {
        thumb?: (p: TrovoSliderThumbProps) => React.ReactElement;
    }
) {
    const {thumb, ...rest} = props;
    return (
        <StlyedSlider
            slots={{
                thumb: thumb ? thumb : TrovoSliderGen
            }}
            {...rest}
        />
    );
}
