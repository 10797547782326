import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import SideWidget from '../SideWidget';
import PlanRectangle from './PlanRectangle';
import DemoRectangle from './DemoRectangle';
import {useOutletContext, useNavigate} from 'react-router-dom';
import {useSubscriptions} from '../../../hooks/useSubscriptions';
import {useAuth0} from '@auth0/auth0-react';
import {getAppConfig} from '../../../utils/config';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor, AlertProps} from '@mui/material/Alert';

interface PlanInformationProps {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PlanInformation: React.FC<PlanInformationProps> = () => {
    // Application state for Account Management is compiled at a hire level and passed down to this component and others.
    const {accountState, setAccountState, setShowBanner} = useOutletContext<{
        accountState: AccountState;
        setAccountState: AccountState;
        setShowBanner: (show: boolean) => void;
    }>();
    const {getAccessTokenSilently} = useAuth0();
    const [loading, setLoading] = React.useState(false);
    const {apiHost} = getAppConfig();
    const [snackbar, setSnackbar] = useState<{open: boolean; message: string; severity: AlertColor}>({
        open: false,
        message: '',
        severity: 'error'
    });

    const handleChangePlan = async (planId: string, token?: string, code?: string) => {
        const bodyObject = {
            planId,
            ...(token && {token}),
            ...(code && {coupon: code})
        };
        try {
            const response = await fetch(`${apiHost}/subscriptions/change-plan`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObject)
            });
            const data = await response.json();
            console.log('data: ', data);
            if (response.ok && data.status === 'Success') {
                // navigate("/?welcome=true")
                window.location.href = '/?welcome=true';
                return 'Good';
            } else {
                console.log(data.status);
                setLoading(false);
                setSnackbar({open: true, message: 'Error submitting payment details', severity: 'error'});
                return 'Error';
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            setSnackbar({open: true, message: 'Error submitting payment details', severity: 'error'});
            return 'Error';
        }
    };
    if (!accountState?.plans) return null;

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                marginTop="59px"
                paddingBottom="100px"
                paddingLeft="2px"
                justifyContent="space-between"
                alignItems="flex-start">
                <Box display="flex" flexDirection="column" gap="35px">
                    {accountState.plans
                        .filter(plan => plan.period_unit === 'month')
                        .sort((planA: any, planB: any) => planB.price - planA.price)
                        .map(plan =>
                            plan.type === 'paid' ? (
                                <PlanRectangle
                                    key={plan.id}
                                    current={plan.current}
                                    planInfo={plan}
                                    changePlan={(token?, code?) => handleChangePlan(plan.id, token, code)}
                                />
                            ) : (
                                <DemoRectangle
                                    key={plan.id}
                                    current={plan.current}
                                    planInfo={plan}
                                    changePlan={() => handleChangePlan(plan.id)}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            )
                        )}
                    <Box marginLeft="29px">
                        <Typography variant="label" size="large-prominent" marginRight="10px">
                            Need to cancel?
                        </Typography>
                        <Typography variant="label" size="large">
                            Call our support team at +1 (484) 595-0233, extension 350.
                        </Typography>
                    </Box>
                </Box>
                <SideWidget
                    subscription={accountState.subscription}
                    paymentSource={accountState.paymentSource}
                    plan={accountState.plans.find(plan => plan.current === true)}
                />
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({open: false, message: '', severity: 'error'})}>
                <Alert
                    onClose={() => setSnackbar({open: false, message: '', severity: 'error'})}
                    severity={snackbar.severity}
                    sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default PlanInformation;
