import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Typography, Button} from '@mui/material';
import {Settings} from '@mui/icons-material';

interface WidgetBoxProps {
    title?: string;
    children?: React.ReactNode;
}

const WidgetBox: React.FC<WidgetBoxProps> = ({title, children}) => {
    const navigate = useNavigate();
    return (
        <Box borderRadius="20px" width="264px" minWidth="264px" bgcolor="#fff" overflow="hidden">
            <Box padding="24px" marginBottom="24px">
                <Typography variant="title" size="medium" color="on-surface" marginBottom="24px">
                    {title}
                </Typography>
                {children}
            </Box>

            <Box padding="16px 24px" display="flex" flexDirection="row" justifyContent="space-around" bgcolor="surface-container">
                <Button
                    onClick={() => navigate('/account/plan-info')}
                    startIcon={<Settings sx={{color: 'primary.main'}} />}
                    sx={{padding: '10px 6px 10px 12px'}}>
                    View current plan
                </Button>
            </Box>
        </Box>
    );
};

export default WidgetBox;
