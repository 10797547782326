import {Box, Button, Modal, Container, Input, InputLabel, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import * as Plotly from 'plotly.js';

import {InputWithSelect} from '../inputs/InputWithSelect';
const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};
type DownloadPlotModalProps = {
    open: boolean;
    setOpen: Function;
    figure?: Readonly<HTMLElement>;
    name: string;
    defaultWidth: number;
    defaultHeight: number;
};
export function DownloadPlotModal({open, setOpen, figure, name, defaultWidth, defaultHeight}: DownloadPlotModalProps) {
    const theme = useTheme();
    const [imageOptions, setImageOptions] = useState({} as Plotly.DownloadImgopts);
    useEffect(() => {
        setImageOptions({
            filename: name,
            format: 'jpeg',
            width: defaultWidth,
            height: defaultHeight
        } as Plotly.DownloadImgopts);
    }, [figure, name, defaultHeight, defaultWidth]);
    function dlGraph() {
        Plotly.downloadImage(figure!, {
            ...imageOptions
        }).then(data => setOpen(false));
    }
    return (
        <Modal
            open={open}
            onClose={() => {
                setImageOptions({
                    filename: name,
                    format: 'jpeg',
                    width: defaultWidth,
                    height: defaultHeight
                } as Plotly.DownloadImgopts);
                setOpen(!open);
            }}>
            <Box sx={modalStyle} display={'flex'} justifyContent={'center'}>
                <Container>
                    <InputWithSelect
                        title={'Filename'}
                        inputVal={name}
                        update={({value}) => {
                            setImageOptions({
                                ...imageOptions,
                                filename: value as string
                            });
                        }}
                        options={['jpeg', 'png', 'svg']}
                        selectLabel={'Format'}
                        optionVal={imageOptions.format}
                    />
                    <br />
                    <InputLabel variant="standard" id={'Width'} sx={{...theme.styles.label.medium}}>
                        Width
                    </InputLabel>
                    <Input
                        sx={{
                            height: 30,
                            '& .MuiFormLabel-root': theme.styles.label.medium
                        }}
                        id={'Width'}
                        name={'Width'}
                        type="number"
                        value={imageOptions.width}
                        onChange={e => {
                            const w = parseInt(e.target.value);
                            const h = w * (defaultHeight / defaultWidth);
                            const int = Math.round(h);
                            setImageOptions({
                                ...imageOptions,
                                width: w,
                                height: int
                            });
                        }}
                    />
                    <InputLabel variant="standard" id={'Height'} sx={{...theme.styles.label.medium}}>
                        Height
                    </InputLabel>
                    <Input
                        sx={{
                            height: 30,
                            '& .MuiFormLabel-root': theme.styles.label.medium
                        }}
                        id={'Hight'}
                        name={'Height'}
                        type="number"
                        value={imageOptions.height}
                        onChange={e => {
                            const h = parseInt(e.target.value);
                            const w = h * (defaultWidth / defaultHeight);
                            setImageOptions({
                                ...imageOptions,
                                width: Math.round(w),
                                height: h
                            });
                        }}
                    />
                    <Button sx={{padding: 'unset'}} onClick={() => dlGraph()}>
                        download plot
                    </Button>
                </Container>
            </Box>
        </Modal>
    );
}
