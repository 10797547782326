import React from 'react';
import {Stack} from '@mui/material';
import {ChipSelect} from '../../inputs/ChipSelect';

export function GOcontroller({compareList, compare, ontology = [], ontologyList = [], update}: any) {
    return (
        <Stack>
            <ChipSelect
                title={'Select a Comparison'}
                condition={ontology.length > 0 && compare.length === 0}
                tip={'Choose at least one comparison'}
                name={'compare'}
                currentSelections={compare}
                selectionList={compareList}
                update={update}
            />

            <ChipSelect
                title={'Select an Ontology'}
                condition={ontology.length === 0}
                tip={'Need at least one Ontology'}
                name={'ontology'}
                currentSelections={ontology}
                selectionList={ontologyList}
                update={update}
            />
        </Stack>
    );
}
