export const sequencing_details = [
    {
        name: 'analyzed_molecule',
        readable: 'Analyzed Molecule',
        // select: ["mRNA", "mRNA + IncRNA", "smallRNA"],
        select: ['mRNA']
    },
    {
        name: 'rna_selection_method',
        readable: 'RNA Selection Method',
        select: ['polyA Selection', 'rRNA Depletion', 'Size Fractionation']
    },
    {
        name: 'sequencing_adapter',
        readable: 'Sequencing Adapter',
        select: ['Illumina TruSeq adapters', 'Illumina Nextera adapters', 'AmpliSeq for Illumina Panels adapters']
    },
    {
        name: 'sequencing_platform',
        readable: 'Sequencing Platform',
        select: ['Illumina']
    },
    {
        name: 'platform_model',
        readable: 'Platform Model',
        select: [
            'Illumina Genome Analyzer IIx',
            'Illumina HiScanSQ',
            'Illumina HiSeq 1000',
            'Illumina HiSeq 1500',
            'Illumina HiSeq 2000',
            'Illumina HiSeq 2500',
            'Illumina HiSeq 3000',
            'Illumina HiSeq 4000',
            'Illumina HiSeq X Five',
            'Illumina HiSeq X Ten',
            'Illumina MiSeq',
            'Illumina MiSeqDx',
            'Illumina MiniSeq',
            'Illumina MiniSeqDx',
            'Illumina NextSeq 500',
            'Illumina NextSeq 550',
            'Illumina NextSeq 1000',
            'Illumina NextSeq 2000',
            'Illumina NovaSeq 6000',
            'Illumina iScan',
            'Illumina iSeq 100'
        ]
    },
    {
        name: 'sequencing_read_type',
        readable: 'Sequencing Read Type',
        select: ['paired', 'singled']
    },
    {
        name: 'sequencing_sense',
        readable: 'Sequencing Sense',
        select: ['Forward', 'Reverse', 'Unstranded']
    },
    {
        name: 'files_per_sample',
        readable: 'Number of Files Per Sample',
        select: ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    }
    // {
    //     name: "sequence_end",
    //     readable: "Sequence End",
    //     select: ["Single", "Paired"]
    // }
];
