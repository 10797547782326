import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    Typography,
    Box,
    Grid,
    IconButton,
    styled,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {
    CheckCircleOutlineOutlined,
    CircleOutlined,
    FileDownload,
    FileUpload,
    HighlightOff,
    Summarize,
    TextSnippet,
    Upload
} from '@mui/icons-material';
import {useBff} from '../../../../utils/config';

const Step3HasSamples = ({samples, setStep, setShowUploader}: any) => {
    console.log(samples);
    return (
        <Box p={2}>
            <Typography variant="headline" size="small">
                <CheckCircleOutlineOutlined
                    sx={{
                        fontSize: '28px',
                        color: 'primary.main',
                        marginRight: '7px'
                    }}
                />
                Step Completed
            </Typography>

            <Typography variant="body" size="large" px={4} mt={1}>
                You have added the following samples:
            </Typography>
            <hr style={{width: '90%'}} />
            <Table
                aria-label="samples table"
                sx={{width: '80%', ml: '8%', border: '1px solid', borderColor: 'surface-container-high', my: 1}}>
                <TableHead sx={{th: {bgcolor: 'surface-container-high', height: '25px', py: 0}}}>
                    <TableRow>
                        <TableCell>Sample Name</TableCell>
                        <TableCell>Files</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {samples.map((sample: any) => (
                        <TableRow key={sample.name} sx={{th: {height: '25px', py: 0}}}>
                            <TableCell component="th" scope="row" sx={{borderRight: '1px solid', borderColor: 'surface-container-high'}}>
                                {sample.name}
                            </TableCell>
                            <TableCell sx={{height: '20px', py: 0}}>
                                {sample.files.map((file: any, index: any) => (
                                    <React.Fragment key={index}>{file.name + '; '}</React.Fragment>
                                ))}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <hr style={{width: '90%'}} />
            <Typography variant="body" size="large" px={4} pb={3} mt={2}>
                Would you like to use the bulk uploader again to create additional samples?
                <br />
                <b>Please Note</b>: Samples that have the same name as samples that have already been created will overwrite the existing
                sample. Please ensure each new sample has a unique name.
            </Typography>
            <Box sx={{display: 'flex', gap: 3, justifyContent: 'center', width: '90%'}}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setShowUploader(true);
                    }}>
                    Use Bulk Upload to Create More Samples
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setStep(3);
                    }}>
                    Continue to Edit Samples
                </Button>
            </Box>
        </Box>
    );
};

export default Step3HasSamples;
