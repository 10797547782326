import {GeneralGraphProps, GlobalConfig} from '../../types/GraphTypes';
import {Font, Layout, Padding} from 'plotly.js';

export function setSimpleLayouts(
    {
        title,
        width,
        height,
        font,
        showGrid,
        yTitle,
        xTitle,
        fontSizeX,
        fontSizeTitle,
        fontSizeY,
        dragmode,
        mRight,
        mLeft,
        mBottom,
        mTop,
        xTitlePos,
        yTitlePos
    }: GeneralGraphProps & GlobalConfig,
    layout: Partial<Layout>
): Partial<Layout> {
    // @ts-ignore
    const titleLayout: Partial<{
        text: string;
        font: Partial<Font>;
        xref: 'container' | 'paper';
        yref: 'container' | 'paper';
        x: number;
        y: number;
        xanchor: 'auto' | 'left' | 'center' | 'right';
        yanchor: 'auto' | 'top' | 'middle' | 'bottom';
        pad: Partial<Padding>;
    }> = {
        xref: 'paper',
        x: xTitlePos,
        y: yTitlePos
    };
    return {
        ...layout,
        dragmode,
        xaxis: {
            ...layout.xaxis,
            title: {
                ...(typeof layout.xaxis?.title === 'object' && layout.xaxis.title),
                text: xTitle,
                font: {
                    size: fontSizeX
                }
            },
            showgrid: showGrid
        },
        yaxis: {
            ...layout.yaxis,

            title: {
                ...(typeof layout.yaxis?.title === 'object' && layout.yaxis.title),

                text: yTitle,
                font: {
                    size: fontSizeY
                }
            },
            showgrid: showGrid
        },
        title: {
            ...(typeof layout.title === 'object' && layout.title),
            ...titleLayout,

            text: title,
            font: {
                size: fontSizeTitle
            }
        },
        font: {
            ...layout.font,
            family: font
        },
        width,
        height,
        margin: {
            ...layout.margin,
            l: mLeft,
            r: mRight,
            t: mTop,
            b: mBottom
        },
        annotations: []
    };
}
