export function getHeatmapFigure({data}: any) {
    const genes: any[] = [];
    const counts: any[] = [];
    const x: any[] = data.expressionCount.data.map((d: any) => {
        genes.push(d.gene_name);
        genes.push(d.count);
        return d.sample_name;
    });

    return {
        data: [
            {
                x,
                y: genes,
                z: counts,
                type: 'heatmap'
            }
        ],
        layout: {}
    };
}
