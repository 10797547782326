import React, {MouseEventHandler} from 'react';
import {Card, Stack, Typography, Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Button} from '../components/buttons/Button';
import {Footer} from '../components/Footer';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {LogoSvgWithText} from '../components/TrovoLogo';

const ContainerStyled = styled('div')({
    background: "url('https://trovomics-dev-ui-assets.s3.us-east-2.amazonaws.com/login_bg.png')",
    backgroundSize: 'cover'
    // display: "grid"
    // gridTemplateRows: "auto 1fr auto"
});

const ContentWrapperStyled = styled('div')(({theme}) => ({
    width: '100%',
    minHeight: '100vh',
    padding: '100px 156px',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {
        padding: '40px'
    }
}));

const PaddingContainer = styled(Box)(({theme}) => ({
    padding: '34px 44px',
    backgroundColor: '#fff',
    width: '600px',
    [theme.breakpoints.down('md')]: {
        padding: '34px 24px',
        width: '100%',
        marginTop: '100px'
    }
}));

type LoginSignUpProps = {
    login: MouseEventHandler;
    actionName: string;
};

export const LoginSignUp = ({login, actionName}: LoginSignUpProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <ContainerStyled>
            <ContentWrapperStyled>
                <LogoSvgWithText width={isMobile ? 180 : 250} />
                <Typography
                    variant="h2"
                    fontWeight="bold"
                    style={{
                        maxWidth: '800px',
                        margin: '30px 0 50px 0',
                        color: 'white',
                        fontWeight: '500',
                        marginTop: isMobile ? '50px' : '50px',
                        fontSize: isMobile ? '48px' : '60px',
                        lineHeight: isMobile ? '1.3' : '1.2'
                    }}>
                    Your no-code solution for RNA-Seq visualization.
                </Typography>
                <PaddingContainer>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="body1" style={{fontSize: isMobile ? '24px' : '32px'}}>
                            Welcome to Trovomics!
                        </Typography>
                        <Typography style={{marginBottom: '20px'}}>
                            The secure RNA-Seq processing platform that makes data analysis and visualization fast and easy. No coding
                            skills required.
                        </Typography>
                        <Button variant="contained" onClick={login} style={{backgroundColor: 'black'}}>
                            {actionName}
                        </Button>
                    </Stack>
                </PaddingContainer>
            </ContentWrapperStyled>
            <Footer />
        </ContainerStyled>
    );
};
