import {GeneralGraphProps} from '../../../types/GraphTypes';

export * from './GOcontroller';
export * from './dataProcessing';

export interface GOProps extends GeneralGraphProps {
    compare: string[];
    compareList: string[];
    ontology: string[];
    ontologyList: string[];
}

export const goConfig: GOProps = {
    xTitle: 'xValue',
    title: 'GO Plot',
    xTitlePos: 0,
    yTitlePos: 0.9,
    yTitle: '',
    fontSizeTitle: 20,
    fontSizeX: 12,
    fontSizeY: 12,
    width: 800,
    height: 500,
    compare: [],
    compareList: [],
    ontology: [],
    ontologyList: [],
    showGrid: false,
    setDefaults: true,
    mLeft: 0
};
