import React from 'react';

import {ChipSelect} from '../../inputs/ChipSelect';
import {updateFunc} from '../../../types';
import {CompareProps} from './index';

type CompareControllerProps = CompareProps & {
    update: updateFunc;
};
export function CompareController({geneList = [], geneLists = [], update}: CompareControllerProps) {
    return (
        <div>
            <ChipSelect
                title={'Select a Genelist'}
                condition={geneList.length < 2}
                tip={'Choose at least 2 gene list to compare'}
                name={'geneList'}
                currentSelections={geneList}
                selectionList={geneLists}
                update={update}
            />
        </div>
    );
}
