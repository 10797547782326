import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {SketchPicker} from 'react-color';
import {useTheme} from '@mui/material';

export function ColorPicker({color, label, update}: {color: string; label: string; update: Function}) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const box = {
        width: 36,
        height: 14,
        borderRadius: 2,
        background: color
    };
    const swatch = {
        padding: 5,
        background: '#fff',
        borderRadius: 1,
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer'
    };
    const popover: React.CSSProperties = {
        position: 'absolute',
        top: 20,
        zIndex: 2000
    };
    const cover: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    };

    return (
        <Box alignItems={'center'} display={'flex'} marginBottom={2}>
            <Box marginRight={2}>
                <Typography sx={theme.styles.label.small}>{label}</Typography>
            </Box>
            <div style={swatch} onClick={() => setOpen(!open)}>
                <div style={box} />
            </div>
            {open ? (
                <div style={popover}>
                    <div style={cover} onClick={() => setOpen(!open)} />
                    <SketchPicker disableAlpha color={color} onChange={e => update({name: label, value: e.hex})} />
                </div>
            ) : null}
        </Box>
    );
}
