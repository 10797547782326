import React, {useState} from 'react';
import {Box, Typography, TextField, Button} from '@mui/material';
import {CardComponent, CardNumber, CardExpiry, CardCVV} from '@chargebee/chargebee-js-react-wrapper';
import {KeyboardBackspace} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {useCCForm} from '../../../../hooks/useCCForm';
import LoadingButton from '@mui/lab/LoadingButton';
import {getAppConfig} from '../../../../utils/config';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor, AlertProps} from '@mui/material/Alert';

interface UpdatePaymentProps {}

const inputStyles = {
    base: {
        color: '#49454F',
        fontSize: '16px',
        lineHeight: '40px'
    }
};
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UpdatePayment: React.FC<UpdatePaymentProps> = () => {
    const {apiHost} = getAppConfig();
    const {getAccessTokenSilently} = useAuth0();
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useState<{open: boolean; message: string; severity: AlertColor}>({
        open: false,
        message: '',
        severity: 'error'
    });

    const handleUpdatePayment = async (token: string) => {
        console.log('formErrors: ', JSON.stringify(formErrors));
        if (!token) return 'Error';
        try {
            const response = await fetch(`${apiHost}/subscriptions/update-payment`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({token})
            });
            const data = await response.json();
            console.log('UpdatePayment data: ', data);
            if (response.ok && data.status === 'Success') {
                navigate('/account/billing');
                return 'Success';
            } else {
                setSnackbar({
                    open: true,
                    message: 'Invalid payment method. Please check your card information.',
                    severity: 'error'
                });
                setIsLoading(false);
                console.log('Error');
                return 'Error';
            }
        } catch (err) {
            setIsLoading(false);
            setSnackbar({
                open: true,
                message: 'Invalid payment method. Please check your card information.',
                severity: 'error'
            });
            console.log('Error: ', err);
            return 'Error';
        }
    };

    const {
        handleFormChange,
        isComplete,
        isLoading,
        setIsLoading,
        cardRef,
        formErrors,
        handleFormSubmit,
        name: {name, setName}
    } = useCCForm(handleUpdatePayment, setSnackbar);

    return (
        <Box maxWidth="1130px" margin="0 auto" paddingBottom="300px" marginTop="83px">
            <Box display="flex" flexDirection="column" gap="36px" marginLeft="12px">
                <Button variant="light" startIcon={<KeyboardBackspace />} sx={{width: '156px'}} component={Link} to="/account/billing">
                    Back to Billing
                </Button>
                <Typography variant="headline" size="large" color="light-on-surface">
                    Update your payment
                </Typography>
            </Box>
            {/* <Typography variant="title" size="medium" color="light-on-surface" paddingLeft="24px" marginTop="59px">
                Payment on file
            </Typography> */}
            <CardComponent ref={cardRef} className="pay-outer-edit-card" icon={true} onChange={handleFormChange}>
                {formErrors.error && (
                    <Box padding="20px">
                        <Typography color="error" variant="body1">
                            {formErrors.error}
                        </Typography>{' '}
                    </Box>
                )}

                <Box display="flex" flexDirection="column" gap="12px" paddingLeft="24px">
                    <Typography variant="label" size="medium-prominent" color="on-surface">
                        Name on card
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="Name on Card"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        sx={{
                            width: '627px',
                            fontWeight: '400',
                            fontSize: '16px',
                            letterSpacing: '0.5px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#79747e'
                            }
                        }}
                    />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px', paddingLeft: '24px'}}>
                    <Typography variant="label" size="medium-prominent" color="on-surface">
                        Card details
                    </Typography>
                    {formErrors.number && (
                        <Typography color="error" variant="body1">
                            {formErrors.number}
                        </Typography>
                    )}
                    <CardNumber className="pay-input number-edit-card" styles={inputStyles} />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px', paddingLeft: '24px'}}>
                    <Typography variant="label" size="medium-prominent" color="on-surface">
                        CVV
                    </Typography>
                    {formErrors.cvv && (
                        <Typography color="error" variant="body1">
                            {formErrors.cvv}
                        </Typography>
                    )}
                    <CardCVV className="pay-input cvv-edit-card" styles={inputStyles} />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px', paddingLeft: '24px'}}>
                    <Typography variant="label" size="medium-prominent" color="on-surface">
                        Date of Expiration
                    </Typography>
                    {formErrors.expiry && (
                        <Typography color="error" variant="body1">
                            {formErrors.expiry}
                        </Typography>
                    )}
                    <CardExpiry className="pay-input expiry-edit-card" styles={inputStyles} />
                </Box>
            </CardComponent>
            <Box padding="0 24px" sx={{display: 'flex', flexDirection: 'row', gap: '36px', marginTop: '60px'}}>
                <Button variant="text" component={Link} to="/account/billing">
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    disabled={!isComplete}
                    loading={isLoading}
                    onClick={handleFormSubmit}
                    sx={{
                        width: '240px'
                    }}>
                    Submit Payment
                </LoadingButton>
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({open: false, message: '', severity: 'error'})}>
                <Alert
                    onClose={() => setSnackbar({open: false, message: '', severity: 'error'})}
                    severity={snackbar.severity}
                    sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default UpdatePayment;
