import {Box, Typography, Modal, Button} from '@mui/material';
import React, {useState} from 'react';
import ToDemoWarning from './ToDemoWarning';
import LoadingButton from '@mui/lab/LoadingButton';
import {AlertColor} from '@mui/material/Alert';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '778px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    bgcolor: '#FFF',
    borderRadius: '12px',
    boxShadow: 24,
    padding: '44px 60px 37px 55px'
};

interface ToDemoModalProps {
    open: boolean;
    setClose: () => void;
    changePlan: () => Promise<string>;
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const ToDemoModal: React.FC<ToDemoModalProps> = ({open, setClose, changePlan, loading, setLoading}) => {
    return (
        <Modal open={open} onClose={setClose}>
            <Box sx={style}>
                <Box paddingRight="12px" display="flex" flexDirection="column" gap="24px">
                    <Typography variant="headline" size="medium">
                        Are you sure you want to change to the Demo Plan?
                    </Typography>
                    <ToDemoWarning />
                </Box>
                <Box paddingLeft="5px" display="flex" flexDirection="column" marginTop="20px" gap="8px">
                    <LoadingButton
                        variant="contained"
                        loading={loading}
                        onClick={() => {
                            setLoading(true);
                            changePlan();
                        }}>
                        Yes, change my plan
                    </LoadingButton>
                    <Button variant="outlined" onClick={setClose}>
                        No, cancel the change
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ToDemoModal;
