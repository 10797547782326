import React from 'react';

export const camelToHyphenated = (str: string) => {
    return str
        .replace(/([a-z0-9])([A-Z])/g, '$1-$2') // match boundary between a lowercase or digit and an uppercase
        .toLowerCase();
};
export function convertToPx(path: string, sec: any) {
    for (const k in sec) {
        if (k === path) {
            sec[k] = `${sec[k]}px`;
            continue;
        }
        if (typeof sec[k] === 'object' && Object.keys(sec[k]).length > 0) {
            convertToPx(path, sec[k]);
        }
    }
}

// function that takes iso date and returns year (e.g. 2021), month (e.g., August), and day (e.g., 5)
export const getDate = (isoDate: number) => {
    const date = new Date(isoDate * 1000);
    const year = date.getFullYear();
    const month = date.toLocaleString('default', {month: 'long'});
    const day = date.getDate();
    const timestamp = date.getTime();
    return {year, month, day, timestamp};
};

export const executeWithoutPropagation = (event: React.MouseEvent<HTMLElement>, callback: any): void => {
    event.stopPropagation();
    callback();
};
