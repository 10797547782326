import React, {useEffect} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {Check} from '@mui/icons-material';
import ToPaidModal from './ToPaidModal';
import AccountStatsWidget from '../../AccountStatsWidget';
import {mockAccountStatsData} from '../../../utils/mock-data';

interface PlanRectangleProps {
    current: boolean;
    planInfo: Plan;
    changePlan: (token?: string, code?: string) => Promise<string>;
}

const PlanRectangle: React.FC<PlanRectangleProps> = ({current, planInfo, changePlan}) => {
    const [paidModalOpen, setPaidModalOpen] = React.useState(false);

    useEffect(() => {
        if (current) {
            setPaidModalOpen(false);
        }
    }, [current]);

    return (
        <>
            <Box
                width="741px"
                padding="31px 38px 51px 58px"
                display="flex"
                bgcolor={current ? 'surface' : 'surface-container-lowest'}
                flexDirection="column"
                gap="33px"
                style={{
                    background: current
                        ? 'linear-gradient(white, white) padding-box,linear-gradient(to right, #7C57F4, #F7218C) border-box'
                        : 'none',
                    borderRadius: '10px',
                    border: current ? '4px solid transparent' : '1px solid'
                }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="title" size="medium">
                        Subscription plan
                    </Typography>
                    <Button
                        data-test-id="paid-plan-button"
                        variant="contained"
                        disabled={current}
                        onClick={() => setPaidModalOpen(true)}
                        startIcon={current ? <Check sx={{width: '18px', height: '18px'}} /> : false}
                        sx={{width: '250px'}}>
                        {current ? 'Current plan' : 'Upgrade'}
                    </Button>
                </Box>
                <Box display="flex" flexDirection="row" gap="54px">
                    <Box display="flex" flexDirection="column" gap="10px" paddingTop="17px">
                        <Typography variant="title" size="large" color="on-surface-variant">
                            {planInfo.title} Plan
                        </Typography>
                        <Typography variant="body" size="large" marginTop="2px" color="on-surface-variant">
                            {`$${planInfo.price_usd} per ${planInfo.period_unit}`}
                        </Typography>
                        <Typography variant="body" size="small" color="on-surface-variant" width="269px">
                            {planInfo.description}
                        </Typography>
                    </Box>
                    <AccountStatsWidget current={current} data={mockAccountStatsData} />
                </Box>
            </Box>
            <ToPaidModal open={paidModalOpen} setClose={() => setPaidModalOpen(false)} changePlan={changePlan} planInfo={planInfo} />
        </>
    );
};

export default PlanRectangle;
