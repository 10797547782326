import {Button, Typography} from '@mui/material';
import React, {useState, useEffect, startTransition} from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Outlet, useLocation} from 'react-router-dom';
import {Link, useNavigate} from 'react-router-dom';
import BannerMessage from './BannerMessage';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material';
import {useTrovoConfig, getAppConfig} from '../../utils/config';
import {useAuth0} from '@auth0/auth0-react';
import {getDate} from '../../utils/utils';
import {ArrowBack} from '@mui/icons-material';
import {styled} from '@mui/material/styles';
import planInformation from './PlanInformation';

const TabsStyled = styled(Tabs)({
    '& .MuiTabs-indicator ': {
        height: '5px',
        // FYI: my bad just don't have time to fix it
        borderRadius: '0 !important',
        background: 'linear-gradient(to right, #7C57F4, #F7218C)'
    }
});

interface AccountDetailsProps {}

// Account Management state will be fetched in this component (or in a hook)
// and passed down to all children of Account Management (e.g., the content in other tabs).

// function that takes iso date and returns date string (e.g. 2021-08-05)
const getDateString = (isoDate: number) => {
    const date = new Date(isoDate * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
};

const getIsDemoPlan = (planIdentifier: string) => planIdentifier?.includes('demo');

const AccountDetails: React.FC<AccountDetailsProps> = () => {
    const location = useLocation();
    const [value, setValue] = useState(location.pathname);
    const navigate = useNavigate();
    const {user} = useTrovoConfig();
    const [accountState, setAccountState] = useState<any>(null);
    const planIdentifier = accountState?.subscription?.subscription_items[0]?.item_price_id;
    const isDemoPlan = getIsDemoPlan(planIdentifier);
    const [showBanner, setShowBanner] = useState(!isDemoPlan);
    const {getAccessTokenSilently} = useAuth0();
    const {apiHost} = getAppConfig();

    useEffect(() => {
        if (location.pathname === '/account') {
            navigate('plan-info');
        }
        console.log(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        const isDemoPlan = getIsDemoPlan(planIdentifier);
        setShowBanner(!isDemoPlan);
    }, [accountState]);

    useEffect(() => {
        (async () => {
            const response = await fetch(`${apiHost}/subscriptions/get-user-account`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await getAccessTokenSilently()}`
                }
            });
            const data = await response.json();
            // console.log("accountData", data)
            const plans: Plan[] = data.plans.map((plan: any) => ({
                id: plan.id,
                type: plan.price > 0 ? 'paid' : 'demo',
                title: plan.external_name,
                price: plan.price,
                price_usd: (plan.price / 100).toFixed(2),
                price_display: plan.price > 0 ? `$${Math.round(plan.price / 100)}` : 'FREE',
                description: plan.description,
                features: plan.metadata?.features || [],
                current: plan.id === user?.cb_item_price_id || false,
                period_unit: plan.period_unit,
                period: plan.period
            }));

            const yearObjects: any = {};

            data.invoices.forEach((invoice: any) => {
                const {year, month, day, timestamp} = getDate(invoice.date);
                if (!yearObjects[year]) {
                    yearObjects[year] = {
                        year,
                        invoices: []
                    };
                }
                yearObjects[year].invoices.push({
                    date: {
                        year,
                        month,
                        day,
                        timestamp
                    },
                    paymentMethod: 'Credit Card',
                    total: invoice.total,
                    id: invoice.id,
                    status: invoice.status,
                    paid_at: invoice.paid_at,
                    amount_paid: invoice.amount_paid,
                    due_date: getDateString(invoice.due_date),
                    currency_code: invoice.currency_code
                });
            });

            setAccountState({...data, plans: plans, invoices: Object.values(yearObjects)});
        })();
    }, []);
    console.log('accountState', accountState);

    useEffect(() => {
        console.log('accountState', accountState);
    }, [accountState]);

    if (!accountState) return <Box>Loading...</Box>;

    const {year, month, day} = getDate(accountState.customer.created_at);

    return (
        <>
            <Box bgcolor={'surface-container-lowest'} mb={0}>
                <Box maxWidth="1130px" margin="0 auto">
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<ArrowBack />}
                        sx={{color: 'on-secondary-container', mt: 3}}
                        onClick={() => {
                            startTransition(() => {
                                navigate('/');
                            });
                        }}>
                        <Typography variant="label" size="large">
                            Back to Main Dashboard
                        </Typography>
                    </Button>
                    <Typography variant="headline" size="large" marginTop="30px" color="on-surface">
                        Billing & Plan Details
                    </Typography>
                    <Typography variant="title" size="medium" marginTop="6px" marginBottom="19px" color="on-surface-variant">
                        Joined {month} {day}, {year}
                    </Typography>
                    <BannerMessage
                        show={showBanner}
                        setHide={() => setShowBanner(false)}
                        title="Successful payment and plan change"
                        message="Congratulations, on upgrading your account! You now have access to additional features, analysis hours, and storage. If you have any questions, be sure to visit our Help Center where you can find tutorials, FAQs, and more."
                        // links={[
                        //     {
                        //         text: "First Link",
                        //         href: "#"
                        //     },
                        //     {
                        //         text: "Second Link",
                        //         href: "#"
                        //     },
                        //     {
                        //         text: "Third Link",
                        //         href: "#"
                        //     },
                        //     {
                        //         text: "Fourth Link",
                        //         href: "#"
                        //     },
                        //     {
                        //         text: "Fifth Link",
                        //         href: "#"
                        //     }
                        // ]}
                    />
                    <TabsStyled
                        value={value}
                        onChange={(event, newValue) => setValue(newValue)}
                        sx={{
                            marginTop: '32px',
                            color: 'on-surface-variant',
                            borderBottom: '1px solid',
                            borderBottomColor: 'surface-container-highest',
                            '& .MuiTab-root': {
                                textTransform: 'none',
                                padding: '12px 0px',
                                minWidth: '0px',
                                fontWeight: '500',
                                letterSpacing: '0.1px',
                                lineHeight: '20px'
                            },
                            '& .MuiTab-root.Mui-selected': {color: 'primary.main'},
                            '& .MuiTabs-flexContainer': {
                                justifyContent: 'space-around'
                            },
                            '& .MuiTabs-indicator': {
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'primary.main',
                                height: '3px',
                                borderRadius: '100px 100px 0 0'
                            }
                        }}>
                        <Tab onClick={() => navigate('plan-info')} value="/account/plan-info" label="Plan information" />
                        {user?.cb_item_price_id.indexOf('demo') === -1 && (
                            <Tab onClick={() => navigate('billing')} value="/account/billing" label="Billing" />
                        )}
                        <Tab onClick={() => navigate('invoices')} value="/account/invoices" label="Invoices" />
                    </TabsStyled>
                </Box>
            </Box>
            <Box maxWidth="1130px" margin="0 auto" paddingBottom="300px">
                <Outlet context={{accountState, setAccountState, setShowBanner}} />
            </Box>
            {/* {value === 2 && <Invoices />} */}
        </>
    );
};

export default AccountDetails;
