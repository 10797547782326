import React, {useState} from 'react';
import {Select, MenuItem, InputLabel, FormControl, Button, Box, Typography, styled} from '@mui/material';

type FilterCriteria = {
    column: string;
    condition: string;
    value: string;
};

type FilterListProps<T> = {
    initialList: T[];
    filterOptions?: string[];
};
const FilterContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '50vw',
    marginLeft: '16%'
});

function FilterList({initialList, filteredList, setFilteredList, setShowFilters}: any) {
    const [filters, setFilters] = useState<FilterCriteria[]>([{column: '', condition: '', value: ''}]);

    const parseCategories = (data: any[]) => {
        const result: Record<string, Set<any>> = {};
        data?.forEach(item => {
            const categories = item.other_categories;
            for (const key in categories) {
                if (!result[key]) {
                    result[key] = new Set();
                }
                result[key].add(categories[key]);
            }
        });
        const finalResult: any = {};
        for (const key in result) {
            finalResult[key] = Array.from(result[key]);
        }
        return finalResult;
    };
    const filterOptions = parseCategories(initialList);

    const handleAddFilter = () => {
        setFilters([...filters, {column: '', condition: '', value: ''}]);
    };

    const handleFilterChange = (index: number, field: keyof FilterCriteria, value: string) => {
        const newFilters = [...filters];
        newFilters[index][field] = value;
        setFilters(newFilters);
    };

    const applyFilters = (filters: FilterCriteria[], list: any): [] => {
        return filters.reduce((filtered, filter) => {
            if (!filter.column || !filter.condition || !filter.value) {
                return filtered;
            }
            return filtered.filter((item: any) => {
                switch (filter.condition) {
                    case 'equals':
                        return item.other_categories[filter.column] === filter.value;
                    case 'not':
                        return !item.other_categories[filter.column]?.includes(filter.value);
                    default:
                        return true;
                }
            });
        }, list);
    };

    const handleApplyFilters = () => {
        const filtered = applyFilters(filters, initialList);
        setFilteredList(filtered);
    };

    const handleClearAll = () => {
        setFilters([{column: '', condition: '', value: ''}]);
        setFilteredList(initialList);
    };

    return (
        <FilterContainer bgcolor={'surface-container-lowest'} border={1} borderColor={'outline-variant'} p={2} my={2}>
            <Typography display={'flex'} justifyContent={'space-between'} borderBottom={1}>
                Filters: Showing {!filteredList?.length ? initialList?.length : filteredList?.length} of {initialList?.length} items{' '}
                <Button variant="text" onClick={handleClearAll}>
                    Clear all
                </Button>
            </Typography>
            {filters.map((filter, index) => (
                <Box mt={1}>
                    {index > 0 ? <Typography> and </Typography> : <Typography> Where </Typography>}
                    <Box key={index} display={'flex'} justifyContent={'space-evenly'} alignContent={'flex-start'} gap={1}>
                        <FormControl fullWidth>
                            <InputLabel id={`column-select-label-${index}`}>column</InputLabel>
                            <Select
                                labelId={`column-select-label-${index}`}
                                id={`column-select-${index}`}
                                value={filter.column}
                                label="Column"
                                onChange={e => handleFilterChange(index, 'column', e.target.value)}
                                sx={{height: '45px'}}>
                                {Object.keys(filterOptions).map((option: any, optionIndex: any) => (
                                    <MenuItem key={optionIndex} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id={`column-select-label-${index}`}>condition</InputLabel>
                            <Select
                                labelId={`condition-select-label-${index}`}
                                id={`condition-select-${index}`}
                                value={filter.condition}
                                label="Condition"
                                onChange={e => handleFilterChange(index, 'condition', e.target.value)}
                                sx={{height: '45px'}}>
                                <MenuItem value="equals">Equals</MenuItem>
                                <MenuItem value="not">Is Not</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id={`column-select-label-${index}`}>value</InputLabel>
                            <Select
                                labelId={`value-input-label-${index}`}
                                id={`value-input-${index}`}
                                value={filter.value}
                                label="Value"
                                onChange={e => handleFilterChange(index, 'value', e.target.value)}
                                sx={{height: '45px'}}>
                                {filter?.column
                                    ? Object.values(filterOptions[filter?.column]).map((option: any, optionIndex: any) => (
                                          <MenuItem key={optionIndex} value={option}>
                                              {option}
                                          </MenuItem>
                                      ))
                                    : null}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            ))}
            <Box display={'flex'} gap={1} mt={2} px={3}>
                <Button variant="contained" onClick={handleApplyFilters}>
                    Apply filters
                </Button>
                <Button variant="outlined" onClick={handleAddFilter}>
                    Add filter
                </Button>
            </Box>
            <Button variant="text" sx={{alignSelf: 'flex-end', textDecoration: 'underline'}} onClick={() => setShowFilters(null)}>
                X Close
            </Button>
        </FilterContainer>
    );
}

export default FilterList;
