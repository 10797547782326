import {ButtonProps} from '@mui/material';
import MTBThemeExport from './mtb-theme-export.json';
import {camelToHyphenated} from './utils/utils';
import {convertToPx} from './utils/utils';

const typographyStyles = MTBThemeExport.styles;

const stylesToUse = [
    {exportName: 'fontFamilyName', localName: 'fontFamily'},
    {exportName: 'fontWeight', localName: 'fontWeight'},
    {exportName: 'fontSize', localName: 'fontSize', addOn: 'px'},
    {exportName: 'lineHeight', localName: 'lineHeight', addOn: 'px'},
    {exportName: 'letterSpacing', localName: 'letterSpacing', addOn: 'px'},
    {exportName: 'textTransform', localName: 'textTransform'},
    {
        exportName: 'font-variant-numeric',
        localName: 'fontVariantNumeric'
    }
];

const themeTypographyDefs: any[] = [];
for (const [variant, sizeList] of Object.entries(typographyStyles)) {
    for (const [size, styles] of Object.entries(sizeList)) {
        const styleDefs: {[key: string]: string | number} = {};
        for (const [style, value] of Object.entries(styles)) {
            const styleInList = stylesToUse.find(s => s.exportName === style);
            if (styleInList) {
                styleDefs[styleInList.localName] = styleInList.addOn ? `${value}${styleInList.addOn}` : value;
            }
        }
        themeTypographyDefs.push({
            props: {variant: variant as any, size: size as any},
            style: styleDefs
        });
    }
}

// For Backwards Compatibility
const typographyStylesCopy = MTBThemeExport.styles;
convertToPx('lineHeight', typographyStylesCopy);

interface palette {
    [key: string]: string;
}

const getThemeColorDefs = (scheme: 'dark' | 'light') => {
    const themeColorDefs = [];
    for (const [key, value] of Object.entries(MTBThemeExport.schemes[scheme])) {
        themeColorDefs.push({
            props: {color: camelToHyphenated(key)},
            style: {
                color: value
            }
        });
    }
    return themeColorDefs;
};

const getHyphenatedPalette = (scheme: 'dark' | 'light') => {
    const hyphenatedPalette: palette = {};
    for (const [key, value] of Object.entries(MTBThemeExport.schemes[scheme])) {
        hyphenatedPalette[camelToHyphenated(key)] = value;
    }
    return hyphenatedPalette;
};

export const themeMaker = (scheme: 'dark' | 'light') => ({
    palette: {
        mode: scheme,
        ...getHyphenatedPalette(scheme),
        background: {
            default: MTBThemeExport.schemes[scheme].background,
            paper: MTBThemeExport.schemes[scheme].background
        },
        primary: {
            main: MTBThemeExport.schemes[scheme].primary
        },
        secondary: {
            main: MTBThemeExport.schemes[scheme].secondary
        },
        error: {
            main: MTBThemeExport.schemes[scheme].error
        }
    },
    typography: {
        button: {
            letterSpacing: 0,
            textTransform: undefined
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ownerState}: {ownerState: ButtonProps}) => ({
                    fontWeight: 500,
                    fontSize: '14px',
                    fontFamily: 'Maison Neue',
                    borderRadius: '100px',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    },
                    padding: '10px 16px',
                    ...(ownerState.startIcon && {
                        padding: '10px 24px 10px 16px'
                    }),
                    height: '40px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                    '&.Mui-disabled': {
                        backgroundColor: '#DCDCDC'
                    }
                }),
                iconSizeMedium: {
                    '& > *:first-child': {
                        fontSize: '18px'
                    }
                },
                '& .MuiButton-startIcon': {
                    padding: '10px 16px 10px 16px'
                }
            },
            variants: [
                {
                    props: {variant: 'outlined' as 'outlined'},
                    style: {
                        border: '1px solid #79747E'
                    }
                },

                {
                    props: {variant: 'light' as 'light'},
                    style: {
                        backgroundColor: MTBThemeExport.schemes[scheme].secondaryContainer,
                        color: MTBThemeExport.schemes[scheme].onSecondaryContainer
                    }
                }
            ]
        },
        // MuiLoadingButton: {
        //     styleOverrides: {
        //         "& .Mui-disabled": {
        //             backgroundColor: "#f05"
        //         }
        //     }
        // },
        MuiTypography: {
            variants: [
                {
                    props: {variant: 'label' as 'label', size: 'large-prominent'},
                    style: {
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        letterSpacing: '0.1px'
                    }
                },
                {
                    props: {variant: 'label' as 'label', size: 'medium-prominent'},
                    style: {
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '16px',
                        letterSpacing: '0.5px'
                    }
                },
                ...themeTypographyDefs,
                ...getThemeColorDefs(scheme)
            ],
            styleOverrides: {
                root: {}
            },
            defaultProps: {
                variantMapping: {
                    display: 'h1',
                    headline: 'h2',
                    title: 'h3',
                    body: 'p',
                    label: 'label'
                }
            }
        }
    },
    ...MTBThemeExport,
    styles: typographyStylesCopy
});
