import {Flag, CheckCircleOutline, KeyboardArrowDown, KeyboardArrowUp, ErrorOutline} from '@mui/icons-material';
import {TableRow, TableCell, IconButton, Checkbox} from '@mui/material';
import React from 'react';
import {useTrovoConfig} from '../../../../utils/config';

const SampleRow = (props: any) => {
    const {row, selected, onSelect, expId} = props;
    const [open, setOpen] = React.useState(false);
    const {user} = useTrovoConfig();

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: '0'}, bgcolor: selected ? '#dbdce273' : 'on-primary'}} hover>
                <TableCell sx={{width: 10}}>
                    {row?.flag && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell padding="checkbox" sx={{cursor: expId === '4015c7cc-1982-4bd4-ae1c-140a7aa6fba9' ? 'not-allowed' : ''}}>
                    <Checkbox
                        checked={selected}
                        onChange={onSelect}
                        disabled={user.cb_item_price_id?.includes('demo') || expId === '4015c7cc-1982-4bd4-ae1c-140a7aa6fba9'}
                    />
                </TableCell>
                <TableCell>{row.flag ? <Flag sx={{color: '#B3261E'}} /> : ''}</TableCell>
                <TableCell align="left">{row?.name}</TableCell>
                <TableCell align="left">
                    {' '}
                    {row.files.every((f: any) => f.status === 'ready') ? (
                        <div>
                            <CheckCircleOutline color="disabled" sx={{fontSize: '18px'}} /> ready{' '}
                        </div>
                    ) : (
                        <div>
                            <ErrorOutline color="error" sx={{fontSize: '18px'}} /> error
                        </div>
                    )}
                </TableCell>
                {Object.values(row?.other_categories)?.map((c: any) => <TableCell align="left">{c} </TableCell>)}
            </TableRow>
        </React.Fragment>
    );
};

export default SampleRow;
