import {useState, useEffect, useCallback} from 'react';

import {Store} from 'pullstate';
import Plotly, {PlotlyHTMLElement} from 'plotly.js';
import {getVolcanoFigure, volcanoConfig, VolcanoControls, VolcanoControlsLayout} from '../components/graphs/Volcano';
import {OtherTools} from '../components/graphs/OtherTools';
import {DragMode, GraphsConfig} from '../types/GraphTypes';
import {VisData} from '../utils/database';
import {getGODataProps, getGOFigure, goConfig, GOcontroller} from '../components/graphs/GO';
import {GOControllerLayout} from '../components/graphs/GO/GOControllerLayout';

import {setSimpleLayouts} from '../components/graphs/setSimpleLayouts';
import {getHeatmapFigure} from '../components/graphs/Heatmap/dataProccess';
import {heatmapConfig, HeatmapController} from '../components/graphs/Heatmap';
import {HeatmapControllerLayout} from '../components/graphs/Heatmap/HeatmapControllerLayout';
import {getComparePlot, getGeneLists} from '../components/graphs/Compare/dataProcess';
import {CompareController} from '../components/graphs/Compare/CompareController';
import {CompareControllerLayout} from '../components/graphs/Compare/CompareControllerLayout';
import {compareConfig} from '../components/graphs/Compare';
import {getPCAPlot} from '../components/graphs/PCA/dataProcess';
import {PCAController} from '../components/graphs/PCA/PCAController';
import {PCAControlsLayout} from '../components/graphs/PCA/PCAControllerLayout';
import {pcaConfig} from '../components/graphs/PCA';

const globalDefault = {
    font: 'Helvetica, sans-serif',
    dragmode: 'zoom' as DragMode
};
const config: GraphsConfig = {
    globals: globalDefault,
    graphs: [
        {
            name: 'PCA',
            process: getPCAPlot,
            stateFromData: function (data: VisData, setDefaults?: boolean) {
                const geneLists = getGeneLists(data);
                return {
                    geneLists
                };
            },
            controllers: {
                dataController: PCAController,
                styleController: PCAControlsLayout,
                toolsController: OtherTools
            },
            stateKey: 'pca',
            state: pcaConfig
        },
        {
            name: 'Volcano',
            process: getVolcanoFigure,
            stateFromData: function (data: VisData, setDefaults?: boolean) {
                const state = data.comparisons.data.map((c: any) => c.comparison);
                return {
                    compareList: state
                };
            },
            controllers: {
                dataController: VolcanoControls,
                styleController: VolcanoControlsLayout,
                toolsController: OtherTools
            },
            stateKey: 'volcano',
            state: volcanoConfig
        },
        {
            name: 'GO',
            process: getGOFigure,
            stateFromData: function (data: VisData, setDefaults?: boolean) {
                const goList = getGODataProps(data);
                const state = data.comparisons.data.map((c: any) => c.comparison);
                const ret = {
                    compareList: state,
                    ...goList,
                    ...(setDefaults && {
                        ontology: goList.ontologyList,
                        setDefaults: false
                    })
                };
                return ret;
            },
            controllers: {
                dataController: GOcontroller,
                styleController: GOControllerLayout,
                toolsController: OtherTools
            },
            stateKey: 'go',
            state: goConfig
        },
        {
            name: 'Heatmap',
            process: getHeatmapFigure,
            controllers: {
                dataController: HeatmapController,
                styleController: HeatmapControllerLayout,
                toolsController: OtherTools
            },
            stateKey: 'heatmap',
            state: heatmapConfig
        },
        {
            name: 'Comparison',
            process: getComparePlot,
            stateFromData: function (data: VisData, setDefaults?: boolean) {
                const geneLists = getGeneLists(data);
                return {
                    geneLists
                };
            },
            controllers: {
                dataController: CompareController,
                styleController: CompareControllerLayout,
                toolsController: OtherTools
            },
            stateKey: 'comparison',
            state: compareConfig
        }
    ]
};

type GraphState<T> = T & {
    history: [];
};

function getState<T>(defaults: T, store?: T): GraphState<T> {
    return {...defaults, history: []};
}
function setUpState() {
    const stateMap: {[k: string]: any} = {};
    config.graphs.forEach(g => {
        stateMap[g.stateKey] = getState(g.state);
    });
    stateMap['global'] = config.globals;
    return new Store(stateMap);
}

const stateRef = setUpState();
const onlyLayoutList = [
    'title',
    'width',
    'height',
    'font',
    'showGrid',
    'yTitle',
    'xTitle',
    'fontSizeX',
    'fontSizeTitle',
    'fontSizeY',
    'dragmode',
    'mRight',
    'mLeft',
    'mBottom',
    'mTop',
    'xTitlePos',
    'yTitlePos'
];
export function usePlots(data: VisData, figure?: PlotlyHTMLElement) {
    const plotStates = stateRef.useState();
    const [layoutState, setLayoutState] = useState(false);
    const [cur, setCur] = useState(config.graphs[0].state);
    const [tab, setTab] = useState('volcano');
    const newTab = useCallback(
        function (tab: string) {
            setCur({...plotStates[tab], ...plotStates['global']});
            setTab(tab);
        },
        [plotStates]
    );
    useEffect(() => {
        const copy = {...plotStates};
        const graph = config.graphs.filter(g => g.stateKey === tab)[0];
        if (graph.stateFromData) {
            const hydrate = graph.stateFromData(data, copy[tab].setDefaults);
            copy[tab] = {...copy[tab], ...hydrate};
        }
        // @ts-ignore
        setCur({...copy[tab], ...copy['global']});
        stateRef.update(s => {
            s[tab] = copy[tab];
        });

        if (figure) {
            const allProps = {...copy[tab], ...copy['global']};
            if (layoutState) {
                const finishedLayout = setSimpleLayouts(allProps, figure.layout);
                setLayoutState(false);
                Plotly.relayout(figure, finishedLayout);
                return;
            }
            const {traces, layout} = graph.process({
                data,
                ...allProps
            });
            const finishedLayout = setSimpleLayouts(allProps, layout);

            Plotly.react(figure, traces, finishedLayout);
        }
    }, [data, figure, layoutState, plotStates, tab]);

    return {
        tab,
        setTab: newTab,
        globals: plotStates['global'],
        graphs: config.graphs,
        graph: config.graphs.filter(g => g.stateKey === tab)[0],
        graphState: cur,
        updateState: (name: any, val: unknown) => {
            if (onlyLayoutList.indexOf(name) !== -1) {
                setLayoutState(true);
            }
            stateRef.update(s => {
                s[tab][name] = val;
            });
        },
        updateGlobal: (name: any, val: unknown) => {
            if (onlyLayoutList.indexOf(name) !== -1) {
                setLayoutState(true);
            }
            stateRef.update(s => {
                s['global'][name] = val;
            });
        }
    };
}
