import React from 'react';
import {Box, Divider, Typography, Button} from '@mui/material';
import visa from './cards/visa 1.png';
import mastercard from './cards/mastercard.png';
import discover from './cards/discover.png';
import amex from './cards/amex.png';
import {ModeEditOutlineOutlined} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {useOutletContext} from 'react-router-dom';
import {getDate} from '../../../utils/utils';

interface BillingProps {}

const Billing: React.FC<BillingProps> = () => {
    const {accountState} = useOutletContext<{
        accountState: AccountState;
        setAccountState: AccountState;
        setShowBanner: (show: boolean) => void;
    }>();
    if (!accountState) return null;
    const {year, month, day} = accountState.subscription.next_billing_at
        ? getDate(accountState.subscription.next_billing_at)
        : getDate(accountState.subscription.cancelled_at);
    const {
        year: year2,
        month: month2,
        day: day2
    } = accountState.invoices.length > 0 ? getDate(accountState.invoices[0].invoices[0].paid_at) : {year: 0, month: 0, day: 0};

    console.log('accountState: ', accountState);

    return (
        <Box display="flex" flexDirection="column" gap="12px" marginTop="67px" width="652px">
            {accountState.paymentSource.length > 0 && (
                <>
                    <Typography variant="title" size="medium" paddingLeft="24px">
                        Payment on file
                    </Typography>
                    <Divider sx={{marginTop: '28px'}} />
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box display="flex" flexDirection="row" alignItems="center" paddingLeft="24px" gap="19px">
                            <img
                                style={{border: '1px solid #eee', borderRadius: '4px'}}
                                width="38px"
                                src={
                                    accountState.paymentSource[0].card.brand === 'visa'
                                        ? visa
                                        : accountState.paymentSource[0].card.brand === 'mastercard'
                                          ? mastercard
                                          : accountState.paymentSource[0].card.brand === 'american_express'
                                            ? amex
                                            : discover
                                }
                                alt="Visa logo"
                            />
                            <Typography variant="body" size="large">
                                •••• ••• {accountState.paymentSource[0].card.last4}
                            </Typography>
                        </Box>
                        <Button
                            variant="text"
                            sx={{width: '97px'}}
                            startIcon={<ModeEditOutlineOutlined />}
                            component={Link}
                            to="edit-payment">
                            Edit
                        </Button>
                    </Box>
                </>
            )}
            <Divider />
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="title" size="small" paddingLeft="24px">
                    {accountState.subscription.next_billing_at ? 'Next Billing Date' : 'Plan Expiration'}
                </Typography>
                <Typography variant="body" size="medium" paddingRight="6px">
                    {month} {day}, {year}
                </Typography>
            </Box>
            <Divider />
            {accountState.invoices.length > 0 && accountState.paymentSource.length > 0 && (
                <>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="title" size="small" paddingLeft="24px">
                            Last Payment
                        </Typography>

                        <Typography variant="body" size="medium" paddingRight="6px">
                            {month2} {day2}, {year2} . ${(accountState.invoices[0].invoices[0].amount_paid / 100).toFixed(2)}
                        </Typography>
                    </Box>
                    <Divider />
                </>
            )}
        </Box>
    );
};

export default Billing;
