import React from 'react';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack} from '@mui/material';

export function GlobalController({font, setUpdate}: {font: string; setUpdate: (k: string, v: unknown) => void}) {
    return (
        <div>
            <Stack>
                <FormControl sx={{m: 1, width: 200, height: 100, marginTop: 2}}>
                    <InputLabel id="font">Plot Font</InputLabel>
                    <Select
                        labelId="font"
                        name={'font'}
                        value={font}
                        autoWidth
                        input={<OutlinedInput label="font" />}
                        onChange={e => setUpdate(e.target.name, e.target.value)}>
                        {['Helvetica, sans-serif', 'Times new roman', 'Brush Script MT, cursive'].map((o: string) => (
                            <MenuItem key={o} value={o}>
                                {o}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </div>
    );
}
